function delay(t, val) {
  return new Promise(function(resolve) {
    if (t <= 0) {
      resolve(val)
    } else {
      setTimeout(resolve.bind(null, val), t)
    }
  })
}

// delayT is optional (defaults to 0)
export default function(array, delayT, fn) {
  if (!fn) {
    fn = delayT
    delayT = 0
  }
  return array.reduce(function(p, item, index) {
    return p.then(function(valuesArray) {
      // no delay on first iteration
      let localDelay = delayT
      if (index === 0) {
        localDelay = 0
      }
      return delay(localDelay, item).then(fn)
        .then((value) => {
          return [...valuesArray, value]
        })
    })
  }, Promise.resolve().then(() => { return [] }))
}
