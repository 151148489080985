
import { fetch } from '@/services/base'

import Transformer from '@/transformers/transformer'

const SINISTERS_PER_PROCESS = 45

const SinisterTransformer = ({
  sinister_id: id, sinister_number: sinisterNumber,
  assignment_date: assignmentDate, slug, affected_count: affectedCount,
  affectedsalternateprocesses: affectedAlternateProcesses
}) => ({
  id, sinisterNumber, assignmentDate, slug, affectedCount,
  affectedAlternateProcesses: affectedAlternateProcesses ? affectedAlternateProcesses.split(",") : null
})

class SinistersTransformer extends Transformer {
  static fetch({ avg, count, data }) {
    return {
      avg, count, data: data ? data.map(SinisterTransformer) : []
    }
  }
}

const paramsTransformer = ({
  fromDate, toDate, supervisors, acos, customers, zones, jobTypes
}) => (
  {
    toDate: toDate || undefined,
    fromDate: fromDate || undefined,
    supervisor: (supervisors && supervisors.length) ? supervisors.join(',') : undefined,
    aco_username: (acos && acos.length) ? acos.join(',') : undefined,
    zone: (zones && zones.length) ? zones.join(',') : undefined,
    customer: (customers && customers.length)  ? customers.join(',') : undefined,
    typeSinister: (jobTypes && jobTypes.length) ? jobTypes.join(',') : undefined
  }
)

/**
 * Fetches sinister list by status
 * @return {Promise} An http GET request
 */
const fetchSinisters = (status, filters = {}) => {
  return fetch('macro_process', {
    useTransformer: SinistersTransformer,
    id: status.slug,
    urlGroup: 'sinister',
    config: {
      params: paramsTransformer(filters)
    }
  })
}

export default fetchSinisters
