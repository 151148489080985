
import Vue from 'vue'

const types = {
  CHECK: 'CHECK',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT'
}

export default {
  namespaced: true,
  state: {
    accessToken: '',
    authenticated: false
  },
  mutations: {
    [types.CHECK] (state) {
      const accessToken = Vue.$storage.getItem('accessToken')
      state.authenticated = !!accessToken
      state.accessToken = accessToken
      if (accessToken) {
        try {
          Vue.$http.defaults.headers.common.Authorization = `JWT ${accessToken}`
        } catch {
          console.error('Could not set headers')
        }
      }
    },

    [types.LOGIN] (state, token) {
      state.authenticated = true
      state.accessToken = token
      Vue.$storage.setItem('accessToken', token)
      if (Vue.$http.defaults) {
        Vue.$http.defaults.headers.common.Authorization = `JWT ${token}`
      }
    },

    [types.LOGOUT] (state) {
      state.authenticated = false
      state.accessToken = ''
      Vue.$storage.removeItem('accessToken')
      if (Vue.$http.defaults) {
        Vue.$http.defaults.headers.common.Authorization = null
      }
    }
  },
  actions: {
    check ({ commit }) {
      commit(types.CHECK)
    },

    login ({ commit, dispatch }, token) {
      commit(types.LOGIN, token)
    },

    logout ({ commit, dispatch }) {
      commit(types.LOGOUT)
      // dispatch('account/store', {}, { root: true })
    }
  }
}
