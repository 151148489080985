/**
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 */

export default (obj) => {
  const formData = new FormData()
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      formData.append(key, obj[key])
    }
  }
  return formData
}
