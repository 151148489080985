
import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'Complaints',
    component: Layout,
    children: [
      {
        path: '/quejas',
        name: 'Complaints Listing',
        props: true,
        component: () => import('@/pages/Reports/Complaints'),
        meta: {
          showInSidebar: true,
          icon: 'mdi-alert-decagram',
          title: 'Quejas',
          ifCan: {
            view: 'Sinister/complaint'
          },
          excludeGroup: EXTRANET_GROUPS
        }
      }
    ]

  }
]
