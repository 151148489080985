
import Transformer from '@/transformers/transformer'
// import {fromNowDate} from '@/filters'
export default class NotificationTransformer extends Transformer {
  static fetch(notification) {
    let data = {
      id: notification.id,
      verb: notification.verb,
      description: notification.description,
      // from_date: fromNowDate(notification.timestamp),
      level: notification.level
    }
    if (notification.data) { data.type = notification.data.type }

    return data
  }

  static send(notification) {
    let data = {
      id: notification.id,
      unread: false
    }
    return data
  }
}
