import Vue from 'vue'

/**
 * @author Mariela Temozihui TL. <mariela.temozihui@jbge.com.mx>
 *
 *  Auditor
 * @return {Object}
 */

export default (zone, customer) =>
  Vue.$http.get(`customer/auditor-by-customer-zone/${zone}/${customer}/`)
    .then(response => response.data)
