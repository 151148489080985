import Axios from 'axios'
import store from '@/store'
import HandledError from '@/utils/handledError'
import router from '@/router'

export default {
  install (Vue) {
    Vue.$http = Axios
    Object.defineProperty(Vue.prototype, '$http', {
      get () {
        return Axios
      }
    })
    const PRODUCTION = process.env.NODE_ENV === 'production'
    // Setting Axios
    Axios.defaults.baseURL = (PRODUCTION
      ? '/api/v1/'
      : process.env.VUE_APP_API_LOCATION || 'http://localhost:8000/api/v1/'
    )
    Axios.defaults.xsrfCookieName = 'csrftoken'
    Axios.defaults.xsrfHeaderName = 'X-CSRFToken'
    Axios.defaults.headers.common.Accept = 'application/json'

    Axios.interceptors.response.use(
      response => response, (error) => {
        if (error.response && error.response.status === 401) {
          store.dispatch('auth/logout')
            .then(() => {
              console.warn('SESSION EXPIRED', { error })
              router.push({
                name: 'Login'
              })
            })
        }
        throw new HandledError(error)
      })
  }
}
