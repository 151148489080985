/**
 * @vuepress
 * ---
 * title: RegisterSinisterJobType
 */

import Transformer from '../transformer'

/**
 * @author José Luis Ruiz
 */
class RegisterSinisterJobType extends Transformer {
  static fetch(job) {
    return {
      'slug': job.slug,
      'id': job.id,
      'name': job.name
    }
  }
  static send(list) {
    return {
    }
  }
  static update(list) {
  }
}
export default RegisterSinisterJobType
