/**
 * @vuepress
 * ---
 * title: Advance Report
 */

import Transformer from '../transformer'
import { assetURL } from '@/utils'

class sinisterTransformer extends Transformer {
  static fetch({ id, date, provider, sinister, supervisor, total, amount, subtotal, affected, payment,
    user_requesting: request, percentage, status, codi_status: codiStatus, binnacle_report: binnacle, comment, data_xmls: invoices }) {
    return {
      id,
      sinisterNumber: sinister && sinister.sinister_number,
      sinisterSlug: sinister && sinister.slug,
      binnacle,
      requestDate: date,
      providerName: provider.name,
      providerCommercial: provider.commercial_name,
      providerNumber: provider.provider_number,
      providerSlug: provider.slug,
      providerTotal: provider.total_provider,
      providerPercentage: provider.percentage,
      supervisor,
      total,
      subtotal,
      amount,
      casualty: { id: affected },
      request,
      payment,
      codiStatus,
      codiStatusBackup: codiStatus,
      comment,
      commentBackup: comment,
      percentage,
      status,

      modified: false, // Needed to identify when binnacle or codiStatus were modified
      toDelete: false, // Needed to identify wich invoices will be deleted
      invoices: invoices ? invoices
        .map((item) => {
          let xml = item.xml_file ? assetURL(item.xml_file) : ''
          let pdf = item.pdf_file ? assetURL(item.pdf_file) : ''
          let name
          let file = xml || pdf
          if (file) {
            let completeName = file.split('/').pop()
            name = completeName.split('.').shift()
          }
          return {
            id: item.pk,
            name,
            pdf,
            xml
          }
        }) : []
    }
  }
}
export default sinisterTransformer
