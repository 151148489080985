import Vue from 'vue'
import store from './../../store'

/**
 * Check user token expiration status
 * @return {Promise} An http request that verifies token
 */
const verifyToken = () => {
  const token = store.state.auth.accessToken
  return Vue.$http.post('/auth/token-verify/', { token })
}

export default verifyToken
