
import Vue from 'vue'

/**
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 *
 * @param casualty casualty's info to be sent
 *
 * Creates a new casualty
 * @return {Object} casualty info
 */

export default (casualtyId) =>
  Vue.$http.get(`sinister/affected/finish/${casualtyId}/`)
    .then(res => res.data)
