import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'BugdetPages',
    component: Layout,
    meta: {
      excludeGroup: EXTRANET_GROUPS,
      ifCan: {
        view: 'Budget/budget'
      }
    },
    children: [
      {
        path: '/siniestros/:sinisterSlug/presupuesto',
        name: 'AffectedBudget',
        component: () => import('@/pages/Sinister/Budget/index'),
        props: true,
        meta: {
          title: 'Presupuesto'
        }
      },
      {
        path: '/siniestros/:sinisterSlug/organizar',
        name: 'OrganizeBudget',
        component: () => import('@/pages/Sinister/Budget/Organize/index'),
        props: true,
        meta: {
          title: 'Organizar conceptos'
        }
      }
    ]
  }
]
