
import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'Servicios',
    component: Layout,
    meta: {
      showInSidebar: true,
      icon: 'mdi-widgets',
      excludeGroup: EXTRANET_GROUPS
    },
    children: [
      {
        path: '/servicios/detalles',
        name: 'Familias, sub y detalles',
        component: () => import('@/pages/Services/Details'),
        meta: {
          showInSidebar: true,
          ifCan: {
            view: [
              'Services/detail', 'Services/family', 'Services/subfamily'
            ]
          }
        }
      },
      {
        path: '/servicios/conceptos',
        name: 'Conceptos',
        component: () => import('@/pages/Services/ConceptsListing'),
        meta: {
          showInSidebar: true,
          ifCan: {
            view: [
              'Services/concept', 'Services/supply'
            ]
          }
        }
      },
      {
        path: '/servicios/insumos',
        name: 'Insumos',
        component: () => import('@/pages/Services/SuppliesListing'),
        meta: {
          showInSidebar: true,
          ifCan: {
            view: 'Services/supply'
          }
        }
      },
      {
        path: '/servicios/motivos',
        name: 'Motivos',
        component: () => import('@/pages/Services/Motif/index.vue'),
        meta: {
          showInSidebar: true,
          ifCan: {
            view: [
              'commons/scopemotifs'
            ]
          }
        }
      }
    ]
  }
]
