import Vue from 'vue'

/**
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 *
 * @param sinister Sinister's slug
 * @param casualty casualty's info to be sent
 *
 * Deletes an exiting casualty
 * @return {Object} casualty info
 */

export default (sinister, casualty) =>
  Vue.$http.delete(`sinister/detail/${sinister}/affected/${casualty}/`)
    .then((res) => res.data)
