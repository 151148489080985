function fixBinary(bin) {
  let length = bin.length
  let buf = new ArrayBuffer(length)
  let arr = new Uint8Array(buf)
  for (let i = 0; i < length; i++) {
    arr[i] = bin.charCodeAt(i)
  }
  return buf
}

export default (image) => {
  return new Promise((resolve) => {
    let imgB64 = image // base64 de la imagen
    let png = imgB64.split(',')[1]
    let binary = fixBinary(window.atob(png))// <-- Usamos la fn "fixBinary"
    let theFile = new Blob([binary], { type: 'image/jpeg' })// <-- Sacamos el encode
    resolve(new File([theFile], 'writed_image.jpg', { type: 'image/jpeg' }))
  })
}
