import Printd from 'printd'

export default (elementHTML) => {
  const cssText = [`
    div img {
      width: 100%;
      height: 95vh;
    }
  `,
  `
    div:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      width: 15%;
      aspect-ratio: 1;
      overflow: hidden;
    }
  `,
  `
    div:nth-child(2) img {
      width: 100%;
      height: 100%;
      transform: scale(1.5);
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
      background-color: rgba(255, 255, 255, 0.4);
    }`
  ]
  if (elementHTML) {
    const p = new Printd()
    p.print(elementHTML, cssText)
  }
}
