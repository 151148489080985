<template>
  <!-- menu bar-->
  <v-app-bar app :dense="toolbarDense" class="noprint" elevation="0">
    <v-app-bar-nav-icon @click.stop="toggleNavbar">
      <!--<v-avatar size="64px">-->
      <!--<v-img :src="logoBack"/>-->
      <!--</v-avatar>-->
      <v-icon>{{ toggleNavbarIcon }}</v-icon>
      <!--Icon Side bar-->
    </v-app-bar-nav-icon>
    <breadcrumbs />
    <v-spacer />
    <Search
      v-if="
        $can('view', 'Sinister', 'sinister') &&
        !EXTRANET_GROUPS.some((g) => $inGroup(g))
      "
      @input="goToFileSinister"
    />
    <template v-if="developmentMode">
      <app-button
        :icon="icon"
        :href="href"
        :tooltip="tooltip"
        v-for="({ icon, href, tooltip }, i) in links"
        :key="i"
      />
    </template>
    <notification />
    <profile />
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import { EXTRANET_GROUPS } from '@/router/utils'
import Breadcrumbs from '../components/widget/AppBreadcrumbs.vue'
import ErrorLog from '../components/widget/AppErrorLog.vue'
import FullScreen from '../components/widget/AppFullScreen.vue'
import Notification from '../components/widget/AppNotification.vue'
import Profile from '../components/widget/AppProfile.vue'
import AppButton from '../components/widget/AppButton.vue'
import Search from '../components/widget/AppSearchSinister.vue'
import logoBack from '@/assets/jb_logo_black.png'

export default {
  name: 'TheLayoutToolbar',
  components: {
    Breadcrumbs,
    ErrorLog,
    FullScreen,
    Notification,
    Profile,
    AppButton,
    Search
  },
  data() {
    const url = process.env.VUE_APP_ASSETS_LOCATION || 'http://localhost:8000/'
    const apiLocation =
      process.env.VUE_APP_API_LOCATION || 'http://localhost:8000/api/v1/'
    const developmentMode = process.env.NODE_ENV === 'development'
    return {
      logoBack,
      links: [
        {
          href: `${url}admin`,
          icon: 'mdi-language-python',
          tooltip: 'Site Admin'
        },
        {
          href: `${apiLocation}documentation`,
          icon: 'mdi-file-document-outline',
          tooltip: 'API Documentation'
        },
        {
          href: 'https://nelsoneax.github.io/vue-vuetify-admin/#',
          icon: 'mdi-brush',
          tooltip: 'Vuetify Admin Template'
        }
      ],
      developmentMode,
      EXTRANET_GROUPS
    }
  },
  computed: {
    ...mapGetters(['toolbarDense', 'navbarShow']),
    toggleNavbarIcon() {
      return this.navbarShow
        ? 'mdi-format-indent-decrease'
        : 'mdi-format-indent-increase'
    }
  },
  methods: {
    goToFileSinister(variable) {
      if (variable) {
        const file = this.$router.resolve({
          name: 'File',
          params: { slug: variable.slug }
        })
        window.open(file.href, '_blank')
      }
    },
    toggleNavbar() {
      this.$store.dispatch('NavbarToggle')
    }
  }
}
</script>

<style lang="scss" scoped>
@media print {
  .noprint {
    display: none;
  }
}
</style>
