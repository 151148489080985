import Vue from 'vue'
import moment from 'moment'

/**
 * @author Jorge Nuñez L. <>
 *
 * @param payload Sinister's, ACO, supervisor info to be sent
 *
 * Reassign supervisor/ACO of sinisters
 * @return {Object} sinister info
 */

function assignSinisterTransformer(assignData) {
  return {
    new_supervisor: assignData.newSupervisor,
    last_supervisor: assignData.oldSupervisor,
    new_aco: assignData.newACO,
    last_aco: assignData.oldACO,
    sinisters_from: assignData.dateFrom ? moment(assignData.dateFrom).format('YYYY/MM/DD') : null,
    sinisters_to: assignData.dateTo ? moment(assignData.dateTo).format('YYYY/MM/DD') : null,
    process: !assignData.includePrevProcesses && assignData.process ? [ assignData.process ] : null,
    prev_processes: assignData.includePrevProcesses && assignData.process ? assignData.process : null
    // Not necessary:
    // specific_sinisters
    // zone
  }
}

export default (payload) =>
  Vue.$http.post(`sinister/reassignment/`, assignSinisterTransformer(payload))
