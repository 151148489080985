
import { fetchCollection } from '@/services/base'
import BusinessCycleTransformer from '@/transformers/macro/businessCycle'

const fetchBusinessCycles = () =>
  fetchCollection('business-cicle',
    {
      useStore: 'catalogs',
      urlGroup: 'macro',
      useTransformer: BusinessCycleTransformer
    })

export default () => [
  fetchBusinessCycles()
]
