
import _ from 'lodash'

const transformPermissions = (permissions) => {
  const permissionObject = {}

  permissions.forEach(per => {
    const app = per.substring(0, per.indexOf('/'))
    const rest = per.substring(per.indexOf('/') + 1)
    const model = rest.substring(0, rest.indexOf('/'))
    const action = rest.substring(rest.indexOf('/') + 1)

    let appN = permissionObject[app]
    if (!appN) {
      appN = {}
      permissionObject[app] = appN
    }

    let modelN = appN[model]
    if (!modelN) {
      modelN = {}
      appN[model] = modelN
    }

    modelN[action] = true
  })

  return permissionObject
}

const reducePermissions = (permissionsArrays) =>
  permissionsArrays.reduce((aP, p) => _.merge(aP, p), {})

export { reducePermissions }

export default transformPermissions
