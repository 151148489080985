
import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'Zonas',
    component: Layout,
    children: [
      {
        path: '/zonas',
        name: 'Zones',
        component: () => import('@/pages/catalogs/zones/index.vue'),
        meta: {
          title: 'Zonas',
          showInSidebar: true,
          icon: 'mdi-map-marker-radius',
          ifCan: {
            view: 'zones/zone'
          },
          excludeGroup: EXTRANET_GROUPS
        }
      }
    ]
  }
]
