import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'Sinister',
    component: Layout,
    meta: {
      title: 'Siniestros',
      showInSidebar: true,
      icon: 'mdi-car',
      excludeGroup: EXTRANET_GROUPS,
      ifCan: {
        view: 'Sinister/sinister'
      }
    },
    children: [
      {
        path: '/siniestros/listado',
        name: 'Sinister List',
        component: () => import('@/pages/Sinister/List/index.vue'),
        meta: {
          title: 'Listado',
          showInSidebar: true,
          forceRefresh: true
        }
      },
      {
        path: '/siniestros/edicion/:id',
        name: 'Register Edition',
        props: true,
        component: () => import('@/pages/Sinister/Register/index.vue'),
        meta: {
          title: 'Registro',
          ifCan: {
            change: 'Sinister/sinister'
          }
        }
      },
      {
        path: '/siniestros/registro',
        name: 'Register New',
        component: () => import('@/pages/Sinister/Register/index.vue'),
        meta: {
          showInSidebar: true,
          title: 'Registro',
          ifCan: {
            add: 'Sinister/sinister'
          }
        }
      },
      {
        path: '/siniestros/reasignacion',
        name: 'Reassigment',
        component: () => import('@/pages/Sinister/Reassignment/index.vue'),
        meta: {
          showInSidebar: true,
          title: 'Reasignación de siniestros'
        }
      },
      {
        path: '/siniestros/:slug',
        name: 'File',
        props: true,
        component: () => import('@/pages/Sinister/File/index.vue'),
        meta: {
          showInSidebar: false,
          title: 'Ficha de siniestro'
        }
      },
      {
        path: '/siniestros/vista_impresion/:sinister',
        name: 'Preview Grid',
        props: true,
        component: () => import('@/pages/Sinister/File/PreviewGrid/index.vue'),
        meta: {
          showInSidebar: false
        }
      },
      {
        path: '/siniestros/archivo_afectado/:casualty',
        name: 'Affected File',
        props: true,
        component: () => import('@/pages/Sinister/File/PreviewGrid/KAffectedFile.vue'),
        meta: {
          showInSidebar: false
        }
      }
    ]
  }
]
