/* ============
 * State of the account module
 * ============
 *
 * The initial state of the account module.
 */

export default {
  accountLoaded: null,
  userId: null,
  username: null,
  treatment: null,
  firstName: null,
  lastName: null,
  secondLastName: null,
  email: null,
  // Profile information
  profileId: null,
  avatar: null,
  phone: null,
  phoneExt: null,
  cellphone: null,
  alternativeEmail: null,
  client: null,
  // address: null,
  // Groups and permissions
  // userArea: null,
  // userRole: null,
  // userRoleCode: null,
  // userType: null,
  groups: [],
  permissions: {},
  provider: null
}
