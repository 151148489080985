import Transformer from '../transformer'
import ConceptTransformer from './concepts'
import schematicTransformer from './schematic'
import { assetURL } from '@/utils'

function checkGeneralized(generalized) {
  return generalized ? {
    ...generalized,
    price: parseFloat(generalized.price).toFixed(2)
  } : null
}
function sendGeneralized(generalized) {
  return generalized && generalized.name ? {
    price: generalized.price ? parseFloat(generalized.price).toFixed(2) : 0,
    name: generalized.name || null
  } : {}
}

function calculateTotal(concepts) {
  return concepts.map(c => c.unit_price_MXN * c.quantity).reduce((a, b) => a + b, 0)
}

class BudgetTransformer extends Transformer {
  static fetch({ apply_taxes: applyTaxes, authorization_file: authorizationFile,
    authorization_file_submission_date: authorizationFileSubmissionDate,
    customer_files_authorizations: customerFilesAuthorizations = [],
    authorization_pdr_file_submission_date: authorizationPDRfileSubmissionDate,
    comments, concepts = [], customer_authorization: customerAuthorization = {}, discount,
    generalized_concept: generalizedConcept, gnp_authorizes: GNPauthorizes, id, price,
    schematic_drawing: schematicDrawing, send_authorization: sendAuthorization,
    send_first_authorization: sendFirstAuthorization, slug, tax_percentage: taxPercentage,
    taxes,
    user_who_registered: userWhoRegistered
  }) {
    const princeNum = +price
    const priceAmount = Math.round(princeNum * 100) / 100
    return {
      applyTaxes,
      authorizationFile: authorizationFile ? assetURL(authorizationFile) : '',
      authorizationFileSubmissionDate,
      authorizationPDRfileSubmissionDate,
      comments,
      concepts: ConceptTransformer.fetchCollection(concepts),

      isAuthorizedByCustomer: customerAuthorization ? customerAuthorization.is_authorized_by_customer : null,
      customerFilesAuthorizations: customerFilesAuthorizations ? customerFilesAuthorizations
        .filter(({ customer_authorization_file: file }) => !!file)
        .map(({ id, add_date: addDate, customer_authorization_file: file }) => {
          let name = file.split('/').pop()
          let format = file.split('.').pop()
          return {
            id,
            addDate,
            name,
            codename: 'AUTH_GNP',
            file: assetURL(file),
            format
          }
        }) : [],
      authorizationDateByCustomer: customerAuthorization ? customerAuthorization.authorization_date_by_customer : null,

      discount: discount ? +discount : 0,
      generalizedConcept: checkGeneralized(generalizedConcept),
      GNPauthorizes,
      id,
      price: priceAmount,
      schematicDrawing: schematicDrawing ? schematicTransformer.fetch(schematicDrawing) : null,
      sendAuthorization,
      sendFirstAuthorization,
      slug,
      taxPercentage: taxPercentage && taxPercentage > 0 ? +taxPercentage : 16,
      taxes: +parseFloat(taxes).toFixed(2),
      userWhoRegistered,

      total: calculateTotal(concepts)
    }
  }

  static send({ casualty, concepts = [], generalizedConcept = null,
    discount = 0, comments = null, deletedConcepts = [],
    taxPercentage = 0, GNPauthorizes
  }) {
    const fromQuotation = concepts.filter(c => c.fromQuotation)
    const fromCatalog = concepts.filter(c => !c.fromQuotation)

    return {
      affected: casualty,
      concepts: ConceptTransformer.sendCollection(fromCatalog),
      quotation_concepts: ConceptTransformer.sendCollection(fromQuotation),
      deleted_concepts: deletedConcepts.map(c => c.concept),
      generalized_concept: generalizedConcept && generalizedConcept.name ? sendGeneralized(generalizedConcept) : null,
      schematic_drawing: null,
      discount: +discount,
      comments,
      gnp_authorizes: GNPauthorizes,
      tax_percentage: +taxPercentage,
      apply_taxes: +taxPercentage > 0
    }
  }
}

export default BudgetTransformer
