
import Vue from 'vue'
import store from './../../store'

const success = (payload) => {
  store.dispatch('catalogs/create', payload)
}

/**
 * Creates a new catalog instance
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 * @param  {String}         catalogURL       Tha catalog url
 * @param  {Object}         payload          The data to create
 * @param  {Object}         options          Creation options
 * @param  {String}         opts.urlGroup    The URL urlGroup/namespace
 * @param  {String|Number}  opts.id          Fetch an specific item
 * @param  {Object}         opts.config      Extra configuration for axios
 * @param  {Boolean}        opts.collection  Force a collection fetch
 * @param  {Boolean}        opts.paginated   Fetch paginated data
 * @param  {Object}         opts.transformer Use a specific transformer
 * @param  {Boolean}        opts.useStore    Save data in vuex store
 * @return {Promise}                         An axios promise to create catalog item.
 */
const createCatalog = (catalogURL, payload, options = {}) => {
  const { source, useStore, urlGroup, useTransformer } = options
  const transformer = useTransformer

  if (transformer && transformer.send) {
    payload = transformer.send(payload)
  }

  const urlPrefix = urlGroup ? `${urlGroup}/` : ''
  let url = `/${urlPrefix}${catalogURL}/`

  let request = Vue.$http.post(url, payload)

  return request.then((response) => {
    let data = response.data

    if (transformer && transformer.fetch) {
      data = transformer.fetch(data)
    }

    if (useStore) {
      success({ type: catalogURL, data: data })
    }

    if (source) {
      source.push(data)
    }

    return data
  })
}

export default createCatalog
