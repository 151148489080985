
import Vue from 'vue'
import store from '@/store'
import AccountTransformer from '@/transformers/account'

/**
 * Fetches account info and saves it in store.
 * @return {Promise} An http request to retrieve user info.
 */
const fetchAccount = () =>
  Vue.$http.get('/users/current/')
    .then(response => {
      const { data } = response
      const account = AccountTransformer.fetch(data)
      store.dispatch('account/store', account)
      return account
    })
const fetchUser = () =>
  Vue.$http.get('/users/current/')
    .then(response => {
      const { data: account } = response
      return AccountTransformer.fetch(account)
    })

const fetchPassword = (obj) =>
  Vue.$http.put('/users/current/change_password/', obj,
    {
      header: {
        'Content-Type': 'application/json'
      }
    }
  ).then(function () {
    console.log('SUCCESS!!')
  })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        console.log('FAILURE!!')
        throw new Error(error.response.status)
      }
    })
// Edit profile
export { fetchAccount, fetchUser, fetchPassword }
