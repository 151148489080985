<template>
  <div>
    <slot
      name="default"
      :notifications="notifications"
      :deleteNotification="deleteNotification"
      :deleteAllNotification="deleteAllNotification"
    >
    </slot>
  </div>
</template>

<script>
import {
  getNotifications,
  connectSocket,
  deleteNotification
} from './../services/'

export default {
  name: 'UserNotifications',
  data() {
    return {
      notifications: []
    }
  },

  methods: {
    fetchData() {
      return getNotifications().then((notifications) => {
        this.notifications = notifications
      })
    },
    connectSocket() {
      return connectSocket((e) => {
        const { notifications } = this
        console.log('Received', { e, notifications })
        notifications.push(e)
      }).then(() => {
        console.log('Connected socket')
      })
    },
    deleteNotification({ id }) {
      const payload = {
        noti_id: id
      }
      deleteNotification(payload)
        .then(() => {
          const index = this.notifications.find((n) => n.id === id)
          this.notifications.splice(index, 1)
        })
        .catch(this.$fireErrorResponse)
    },
    deleteAllNotification() {
      deleteNotification({})
        .then((res) => {
          this.notifications = []
        })
        .catch(this.$fireErrorResponse)
    }
  },

  mounted() {
    this.fetchData()
    this.connectSocket()
  }
}
</script>
