const NEW_BINNACLE_MENTION = 'Nuevo comentario en bitácora'
const NEW_BINNACLE_MENTION_EXTRANET = 'Nuevo comentario en bitácora de Extranet'

const replaceCharacters = (text) => {
  if (typeof text === 'string') {
    return text ? text.replace(/"/g, '\\"').replace(/'/g, '"').replace(/None/g, 'null')
      .replace(/False/g, 'false').replace(/True/g, 'true') : ''
  }
  return text
}
// const getSinisterSlug = (casualtySlug) => {
//   return casualtySlug ? casualtySlug.split('-').shift().toUpperCase().slice() : ''
// }
// const getSinisterNumber = (casualtySlug) => {
//   const slug = getSinisterSlug(casualtySlug)
//   return slug ? [slug.slice(0, slug.length - 2), '/', slug.slice(slug.length - 2)].join('') : ''
// }

export {
  NEW_BINNACLE_MENTION,
  NEW_BINNACLE_MENTION_EXTRANET
}

export default [
  {
    verb: NEW_BINNACLE_MENTION,
    handler: function (notificationData) {
      const { description, data } = notificationData
      let sinisterNumber, sinisterSlug, affected
      try {
        let contentJSON
        if (typeof data === 'string') {
          console.log(replaceCharacters(data))
          const { content = {} } = JSON.parse(replaceCharacters(data))
          contentJSON = content
        } else {
          contentJSON = data && data.content
        }
        sinisterNumber = contentJSON.sinister
        sinisterSlug = contentJSON.sinister
        affected = 0
      } catch {
        console.error('NOTIFICATION PARSE ERROR', { data })
        sinisterNumber = 'SINISTER_NUMBER_PARSE_ERROR'
        sinisterSlug = 'SINISTER_SLUG_PARSE_ERROR'
        affected = 0
      }
      return {
        comment: description
          .split(/(\[\[.*?\]\])/g),
        sinisterNumber,
        sinisterSlug,
        affected
      }
    }
  },
  {
    verb: NEW_BINNACLE_MENTION_EXTRANET,
    handler: function (notificationData) {
      const { description, data } = notificationData
      let sinisterNumber, sinisterSlug, affected
      try {
        let contentJSON
        if (typeof data === 'string') {
          const { content = {} } = JSON.parse(replaceCharacters(data))
          contentJSON = content
        } else {
          contentJSON = data && data.content
        }
        sinisterNumber = contentJSON.sinister
        sinisterSlug = contentJSON.sinister
        affected = 0
      } catch {
        console.error('NOTIFICATION PARSE ERROR', { data })
        sinisterNumber = 'SINISTER_NUMBER_PARSE_ERROR'
        sinisterSlug = 'SINISTER_SLUG_PARSE_ERROR'
        affected = 0
      }
      return {
        comment: description
          .split(/(\[\[.*?\]\])/g),
        sinisterNumber,
        sinisterSlug,
        affected
      }
    }
  }
]
