
import Transformer from '@/transformers/transformer'
export default class supplyTransformer extends Transformer {
  static fetch({ budget_concept: budgetConcept, comment, id,
    is_active: isActive, is_client_price: isClientPrice, price,
    quantity, supply
  }) {
    return {
      ...supply,
      supply: id,
      isActive,
      comment,
      zonePrice: +price,
      quantity: +parseFloat(quantity).toFixed(3),
      isClientPrice,
      budgetConcept
    }
  }

  static send({ supply, zonePrice, quantity }) {
    return {
      supply_id: supply,
      price: +zonePrice,
      quantity: +quantity
    }
  }
}
