import Vue from 'vue'
import sinisterTransformer from '@/transformers/sinister/sinisterWithPermission'
import moment from 'moment'
import 'moment/locale/es-mx'

/**
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 *
 * @param slug sinister's slug
 *
 * Fetch sinisters or sinister
 * @return {Array}
 * @return {Object}
 */
function paramsTransformer({
  page, size = 10, dateFrom,
  dateTo, text, zones = [], clients = [], jobTypes = [], supervisors = [], ECOs = []
}) {
  let finalString = []
  finalString.push(`?page=${page}`)
  finalString.push(`&size=${size}`)
  finalString.push(`&from=${dateFrom ? moment(dateFrom).format('YYYY/MM/DD') : null}`)
  finalString.push(`&to=${dateTo ? moment(dateTo).format('YYYY/MM/DD') : null}`)
  if (text) {
    finalString.push(`&text=${text}`)
  }
  if (jobTypes.length) {
    finalString.push(`&job_type=${jobTypes.join(',')}`)
  }
  if (supervisors.length) {
    finalString.push(`&supervisor=${supervisors.join(',')}`)
  }
  if (clients.length) {
    finalString.push(`&customer=${clients.join(',')}`)
  }
  if (ECOs.length) {
    finalString.push(`&eco=${ECOs.join(',')}`)
  }
  if (zones.length) {
    finalString.push(`&zone=${zones.join(',')}`)
  }
  return finalString.join('')
}

export default (slug = '', options = {}) => {
  const params = paramsTransformer(options)
  return Vue.$http.get(`sinister/permission/${slug ? slug + '/' : params}`)
    .then(({ data }) => {
      if (slug) {
        return sinisterTransformer.fetch(data)
      }
      return {
        ...data,
        results: sinisterTransformer.fetchCollection(data.results)
      }
    })
}
