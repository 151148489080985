/**
 * SweetAlert integration
 * https://sweetalert2.github.io/
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 */

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const sweetMixin = {
  methods: {
    /**
     * Show sweetalert popup
     * @param  {Object|String} arg   The alert payload
     * @return {Promise}             A sweetalert promise
     */
    $fire(arg = {}) {
      return Swal.fire({ ...arg, allowOutsideClick: () => !Swal.isLoading() });
    },

    $fireErrorResponse(error, opts = {}) {
      let html;

      try {
        html = error.htmlString();
      } catch {
        html = error.toString();
      }
      console.log(html, error);
      return this.$fire({
        title: "Ha ocurrido un error.",
        icon: "error",
        html,
        ...opts,
      });
    },

    /**
     * @author José Luis R. <jose.ruiz@jbge.com.mx>
     * @param title
     * @param timer
     * @param method Method to run if user clicks confirm
     * @param callback Next thing to do after CONFIRM click. Ex. Run successMessage
     * @param dismissedCallback Next thing to do after CANCEL click
     */

    $confirmMessage(
      { title, text },
      method,
      callback,
      dismissedCallback = () => {}
    ) {
      return Swal.fire({
        title,
        text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: (res) => {
          return method(res);
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((response) => {
        if (response.value) {
          return callback(response);
        }
        return dismissedCallback(response);
      });
    },
    $successMessage({ text, title, timer }) {
      return Swal.fire({
        icon: "success",
        title: title || "Exitoso",
        text,
        showConfirmButton: false,
        timer: timer || 1500,
      });
    },
    $alertMessage({ title, text, timer, allowOutsideClick = true }) {
      return Swal.fire({
        icon: "warning",
        title,
        text,
        showConfirmButton: false,
        timer: timer || 1500,
        allowOutsideClick: allowOutsideClick,
      });
    },
    $loadingMessage(arg = {}) {
      return Swal.fire({
        title: "Guardado cambios",
        html: "Por favor espere",
        icon: "info",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        onOpen: () => {
          Swal.showLoading();
        },
        ...arg,
      });
    },
  },
};

export default {
  install(Vue) {
    Vue.mixin(sweetMixin);
  },
};
