/**
 * @vuepress
 * ---
 * title: Providers Units
 * ---
 */
import Transformer from '../transformer'

/**
 * @class
 * @author Mariela Temozihui Tlahuel. <mariela.temozihui@jbge.com.mx>
 */
class providerTransform extends Transformer {
  static fetch (provider) {
    return {
      id: provider.id,
      commercialName: provider.commercial_name,
      name: provider.name,
      providerNumber: provider.provider_number,
      rfc: provider.rfc,
      slug: provider.slug
    }
  }
}
export default providerTransform
