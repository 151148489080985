function numberWithCommas (x) {
  // return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const numberString = `${x}`.split('.')
  let integer
  let decimals = '00'
  integer = numberString[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if (numberString.length > 1) {
    decimals = numberString.pop()
  }
  return integer + '.' + decimals
}

export default (original) => {
  let number = original || 0
  return `$${numberWithCommas(number)}`
}
