
import Vue from 'vue'
import store from '@/store'
import AccountTransformer from '@/transformers/account'

/**
 * Updates user account
 * @param  {Object} account The account data
 * @return {Promise}          An http PUT request
 */

export default (account) => {
  return Vue.$http.put('users/current/', AccountTransformer.update(account))
    .then(({ data }) => {
      store.dispatch('account/store', AccountTransformer.fetch(data))
      return data
    })
}
