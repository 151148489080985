
const AL = process.env.NODE_ENV !== 'production'
  ? process.env.VUE_APP_ASSETS_LOCATION || 'http://localhost:8000/'
  : '/'

const assetURL = (url = '') => {
  if (url && url.startsWith('http')) {
    return url
  }
  const alreadyHave = !!url && url.includes('http://localhost:8000/')
  const cleanURL = url && url.replace(/^\/+/g, '')
  return (AL && cleanURL && !alreadyHave) ? (AL + cleanURL) : url
}

export default assetURL
