
import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'businessUnits',
    component: Layout,
    children: [
      {
        path: '/unidades_de_negocio',
        name: 'Business Units',
        component: () => import('@/pages/catalogs/businessUnits/index.vue'),
        meta: {
          title: 'Unidades de negocio',
          showInSidebar: true,
          icon: 'mdi-briefcase',
          ifCan: {
            view: 'zones/businessunits'
          },
          excludeGroup: EXTRANET_GROUPS
        }
      }
    ]
  }
]
