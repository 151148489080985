<template functional>
  <v-card
    width="100%"
    :height="props.height"
    class="d-flex flex-column justify-center align-center"
    outlined
    tile
  >
    <v-icon :size="props.iconSize || 96" class="text-center">
      {{ props.icon }}
    </v-icon>

    <p
      v-if="props.label"
      class="title text-break text-center"
      :class="props.textClass"
      style="width: 100%"
      v-html="props.label"
    />

    <p
      v-if="props.description"
      class="subtitle-2"
      v-html="props.description"
      :class="props.textClass"
    />
  </v-card>
</template>

<script>
/**
  * NoContent component
  * Used as placeholder for empty content
  * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
  */
export default {
  name: 'NoContent',

  props: {
    label: {
      type: String,
      default: 'Nothing to see here!'
    },
    description: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      default: 'mdi-pencil'
    },
    iconSize: {
      type: Number,
      default: () => 96
    },
    minHeight: {
      type: [String, Number],
      default: '600px'
    },
    textClass: {
      type: [String, Object, Array],
      default: ''
    },
    height: {
      type: String,
      default: ''
    }
  }
}
</script>
