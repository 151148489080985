
import Vue from 'vue'
import store from './../../store'

const success = function (payload) {
  store.dispatch('catalogs/delete', payload)
}

const failure = function (payload) {
  console.log('Error deleting element', payload)
}

const deleteFromSource = function (source, data) {
  let index = source.indexOf(data)

  if (index === -1) {
    console.error('Could not delete element from source list.')
    return
  }

  source.splice(index, 1)
}

/**
 * Deletes a catalog item from API
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 * @param  {String} catalog The catalog url
 * @param  {Object} payload The object to be deleted
 * @param  {Object} options Options
 * @return {Promise}        An axios promise to delete item from api
 */
const deleteCatalog = (catalog, payload, options = {}) => {
  const source = options.source
  const { urlGroup } = options
  const prefix = urlGroup ? `${urlGroup}/` : ''
  return Vue.$http.delete(`/${prefix}${catalog}/${payload.id}/`, payload)
    .then(response => {
      if (options.useStore) {
        success({ type: catalog, data: payload })
      }

      if (source) {
        deleteFromSource(source, payload)
      }

      return response
    }).catch((error) => {
      failure(error)
      throw error
    })
}

export default deleteCatalog
