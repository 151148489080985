<template>
  <app-notifications>
    <v-menu
      slot-scope="{ notifications, deleteNotification, deleteAllNotification }"
      offset-y
      origin="center center"
      :nudge-bottom="10"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-badge
          class="alert-badge"
          bordered
          color="error"
          icon="mdi-lock"
          overlap
          :content="notifications.length"
        >
          <v-btn icon text v-on="on">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <v-list
        max-width="900px"
        min-width="200px"
        min
        class="pa-0"
        two-line
        subheader
      >
        <v-subheader
          >Notificaciones
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="deleteAllNotification"
                v-on="on"
                icon
                text
                class="blue--text"
              >
                <v-icon>mdi-delete-sweep</v-icon>
              </v-btn>
            </template>
            <span> Borrar todas las notificaciones </span>
          </v-tooltip>
        </v-subheader>
        <v-divider />
        <v-container fluid class="overflow-y-auto" style="max-height: 600px">
          <v-list-item
            v-for="notification in notifications"
            :key="notification.id"
            @click="handleAction(notification)"
          >
            <v-list-item-icon>
              <v-icon :class="[notification.iconClass]">{{
                notification.icon || "mdi-bell"
              }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <v-icon
                  :color="`${
                    NOTIFICATION_LEVELS_FORMAT[notification.level].color
                  } lighten-1`"
                  >{{ notification.level | notificationLevelIcon }}</v-icon
                >

                <v-chip
                  small
                  :color="`${
                    NOTIFICATION_LEVELS_FORMAT[notification.level].color
                  } lighten-1`"
                  v-html="notificationLabel(notification)"
                />
              </v-list-item-title>
              <v-list-item-subtitle>
                <component
                  :is="notificationComponent(notification)"
                  :value="notification[notificationValue(notification)]"
                  >{{ notificationValue(notification) }}</component
                >
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                @click.stop="deleteNotification(notification)"
                icon
                text
                class="red--text"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-container>
      </v-list>
      <!--  -->
      <!-- <v-divider /> -->
      <!-- <v-subheader>Mostrar todo</v-subheader> -->
    </v-menu>
  </app-notifications>
</template>

<script>
import {
  NEW_BINNACLE_MENTION,
  NEW_BINNACLE_MENTION_EXTRANET
} from '@/plugins/notifications/transformers/notificationTypes'
import BinnacleMentionNotification from './BinnacleMentionNotification'

const NOTIFICATION_LEVELS_FORMAT = {
  info: {
    icon: 'mdi-information',
    color: 'blue'
  }
}

export default {
  name: 'AppNotification',

  components: {
    BinnacleMentionNotification
  },

  data() {
    return {
      NOTIFICATION_LEVELS_FORMAT,
      NOTIFICATION_COMPONENTS: {
        [NEW_BINNACLE_MENTION]: {
          label: ({ sinisterNumber }) =>
            `Nueva mención en bitácora de siniestro &nbsp; <strong>${sinisterNumber}</strong>:`,
          component: 'binnacle-mention-notification',
          key: 'comment',
          onClick: ({ sinisterSlug, affected }) =>
            this.$router.push(
              {
                name: 'File',
                params: {
                  slug: sinisterSlug
                },
                query: {
                  afectado: affected
                }
              },
              () => {}
            )
        },
        [NEW_BINNACLE_MENTION_EXTRANET]: {
          label: ({ sinisterNumber }) =>
            `Nueva mención en bitácora de extranet &nbsp; <strong>${sinisterNumber}</strong>:`,
          component: 'binnacle-mention-notification',
          key: 'comment',
          onClick: ({ sinisterSlug, affected }) =>
            this.$router.push(
              {
                name: 'Extranet Sinister File',
                params: {
                  slug: sinisterSlug
                },
                query: {
                  afectado: affected
                }
              },
              () => {}
            )
        }
      }
    }
  },

  filters: {
    notificationLevelIcon: (level) => {
      const data = NOTIFICATION_LEVELS_FORMAT[level]
      return data ? data.icon : null
    }
  },

  methods: {
    notificationValue({ verb }) {
      const customNoti = this.NOTIFICATION_COMPONENTS[verb]
      return customNoti ? customNoti.key : 'description'
    },
    notificationComponent({ verb }) {
      const customNoti = this.NOTIFICATION_COMPONENTS[verb]

      return customNoti ? customNoti.component : 'span'
    },
    notificationLabel(notification) {
      const { verb } = notification
      const customNoti = this.NOTIFICATION_COMPONENTS[verb]

      const notiLabel = customNoti && customNoti.label
      return notiLabel
        ? typeof notiLabel === 'string'
          ? notiLabel
          : notiLabel(notification)
        : verb
    },
    handleAction(notification) {
      const { verb } = notification
      const customNoti = this.NOTIFICATION_COMPONENTS[verb]
      const handler = customNoti && customNoti.onClick
      if (handler) {
        handler(notification)
      }
    }
  }
}
</script>

<style lang="css">
.v-badge.alert-badge > .v-badge__wrapper {
  margin-top: 8px;
}
</style>
