<template>
  <list-group
    v-if="route.children && route.children.length"
    :item="route"
    :disabled="disabled"
  />
  <list-item
    v-else
    :key="route.path"
    :icon="route.icon"
    :subtext="route.subtext"
    :text="route.text"
    :to="route.to"
    :color="route.disabled ? 'warning' : ''"
    active-class="primary lighten-3"
    :disabled="disabled"
  />
</template>

<script>
import ListItem from '@/components/List/Item'
import ListGroup from '@/components/List/Group'

export default {
  name: 'MenuRouteItem',

  components: {
    ListItem,
    ListGroup
  },

  props: {
    route: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },

  data() {
    return {}
  }
}
</script>

<style lang="css" scoped>
</style>
