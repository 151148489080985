import Vue from 'vue'
import store from './../../store'

/**
 * Refresh user token
 * @return {Promise} An http request that updates user token
 */
const refreshToken = () => {
  const token = store.state.auth.accessToken
  return Vue.$http.post('/auth/refresh_token/', { token })
    .then(response => {
      const token = response.data.token
      store.dispatch('auth/login', token)
      return token
    })
}

export default refreshToken
