/**
 * @vuepress
 * ---
 * title: Permissions
 * ---
 */

import { mapGetters } from 'vuex'
import store from '@/store'

const permissionsMixin = {
  computed: {
    ...mapGetters('account', {
      '$modelPermission': 'modelPermission',
      '$can': 'userCan',
      '$canAdd': 'userCanAdd',
      '$canChange': 'userCanChange',
      '$canView': 'userCanView',
      '$canDelete': 'userCanDelete',
      '$isAdmin': 'userIsAdmin',
      '$inGroup': 'userInGroup'
    })
  }
}

const commentNode = (el, vnode, hide = true) => {
  console.log('hidding node')
  el.style.display = hide ? 'none' : 'block'
}

const permissionsDirective = (checkHasPermission = true, separator = '\\') =>
  (el, bindings, vnode) => {
    const { arg: permission, modifiers: appsModels } = bindings
    Object.entries(appsModels).forEach(([appModel]) => {
      const [app, model] = appModel.split(separator)

      console.assert(!!app && !!model, `wrongly specified app\\model "${appModel}"`)
      const hasPermission = store.getters['account/userCan'](
        permission, app, model
      )

      if (checkHasPermission ? !hasPermission : hasPermission) {
        commentNode(el, vnode)
      }
    })
  }

export default {
  install(Vue) {
    Vue.mixin(permissionsMixin)
    Vue.directive('can', permissionsDirective(true))
    Vue.directive('cant', permissionsDirective(false))
  }
}
