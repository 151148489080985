import Vue from 'vue'
import sinisterTransformer from '@/transformers/sinister/sinister'

/**
 * @author Mariela Temozihui Tl. <mariela.temozihui@jbge.com.mx>
 *
 * @param text sinister's text
 *
 * @return {Object}
 * Deprecated since service is applied directly in @/components/widget/AppSearchSinister
 * Abortcontroller() utilized to cancel request when doing a new request
 * axios v0.22.0 is required
 */

function paramsTransformer(text, opts) {
  return {
    search_data: text,
    process: opts ? opts.process : undefined
  }
}

export default (text, options) => {
  const params = paramsTransformer(text, options)
  return Vue.$http.get(`sinister/search/`, { params })
    .then(response => sinisterTransformer.fetchCollection(response.data)
    )
}
