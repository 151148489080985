/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const STORE = 'STORE'
export const DELETE = 'DELETE'
export const CREATE = 'CREATE'
export const UPDATE = 'UPDATE'

export default {
  STORE,
  DELETE,
  CREATE,
  UPDATE
}
