<template>
  <v-autocomplete
    v-model="selectedSinister"
    :items="listSinisters"
    :loading="isLoading"
    :search-input.sync="search"
    color="blue"
    background-color="grey lighten-5"
    flat
    hide-details
    placeholder="Buscar siniestro"
    prepend-inner-icon="mdi-magnify"
    :no-data-text="
      isLoading && !listSinisters.length ? 'Buscando...' : 'No hay resultados'
    "
    loader-height="4"
    outlined
    dense
    no-filter
    return-object
    style="max-width: 400px"
    v-on="$listeners"
  >
    <template v-slot:selection="{ item }"></template>
    <template v-slot:item="{ item }">
      <div>
        <span>
          <strong class="subtitle-2 font-weight-bold">No. Siniestro:</strong>
          <strong class="ml-2 green--text">{{ item.NumSinister }}</strong>
        </span>
        <br />
        <span>
          <strong class="subtitle-2 font-weight-bold">Folio:</strong>
          {{ item.folio }}
        </span>
        <span>
          <strong class="subtitle-2 font-weight-bold">No. Reporte:</strong>
          {{ item.reportNumber }}
        </span>
        <br />
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
/**
 * Abortcontroller() utilized to cancel request when doing a new request
 * axios v0.22.0 is required
 */

import sinisterTransformer from "@/transformers/sinister/sinister";
import { debounce } from "lodash";

const MIN_LENGTH_QUERY = 2;

export default {
  props: {
    fetchOptions: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      listSinisters: [],
      isLoading: false,
      search: "",
      selectedSinister: null,
      controller: null,
    };
  },
  watch: {
    search(value, oldValu) {
      let text = (value && value.trim()) || "";
      let oldText = (oldValu && oldValu.trim()) || "";

      if (text.length > MIN_LENGTH_QUERY && text !== oldText) {
        this.listSinisters = [];
        if (this.controller && this.isLoading) {
          this.controller.abort("New search");
        }
        this.searchSinister(value);
      } else if (!text && text !== oldText) {
        this.listSinisters = [];
        if (this.controller && this.isLoading) {
          this.controller.abort("Clear search");
        }
      }
    },
  },
  methods: {
    searchSinister: debounce(function (text) {
      this.isLoading = true;
      this.controller = new AbortController();

      const payload = {
        search_data: text,
        process: this.fetchOptions,
      };

      return this.$http
        .get(`sinister/search/`, {
          params: payload,
          signal: this.controller.signal,
        })
        .then((response) => {
          this.listSinisters = sinisterTransformer
            .fetchCollection(response.data)
            .filter((s) => s.isActive);
        })
        .catch((err) => {
          let errMsg;
          try {
            errMsg = err.htmlString();
          } catch {
            errMsg = err.toString();
          }
          console.log(errMsg);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 500),
  },
};
</script>
