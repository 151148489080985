
import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'Users',
    component: Layout,
    children: [
      {
        path: '/usuarios',
        name: 'Usuarios',
        component: () => import('@/pages/Users/Listing/index.vue'),
        meta: {
          title: 'Usuarios',
          showInSidebar: true,
          icon: 'mdi-human-child',
          ifCan: {
            view: 'users/aurorauser'
          },
          excludeGroup: EXTRANET_GROUPS
        }
      }
    ]
  }
]
