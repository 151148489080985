
import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'Dynamic Reports',
    component: Layout,
    meta: {
      excludeGroup: EXTRANET_GROUPS,
      ifCan: {
        view: 'DynamicReports/report'
      }
    },
    children: [
      {
        path: '/reportes_dinamicos/',
        name: 'Dynamic Reports List',
        props: true,
        component: () => import('@/pages/DynamicReports/index.vue'),
        meta: {
          showInSidebar: true,
          icon: 'mdi-table-sync',
          title: 'Reportes dinamicos'
        }
      },
      {
        path: '/reportes_dinamicos/:report/',
        props: true,
        name: 'View Report',
        meta: {
          title: 'Ver reporte'
        },
        component: () => import('@/pages/DynamicReports/Exec')
      }
    ]
  }
]
