import Vue from 'vue'
import RegisterSinisterTransformer from '@/transformers/sinister/sinister'

/**
 * @author José Luis R.
 *
 * @param payload sinister's info to be sent
 *
 * Creates a new siniter
 * @return {Object} sinister info
 */

export default (payload) =>
  Vue.$http.post('sinister/', RegisterSinisterTransformer.send(payload))
    .then(response => response.data)

