import Vue from 'vue'
import notificationTransformer from './../transformers/'

export default () => {
  return Vue.$http.get('commons/notifications/')
    .then(({ data }) => {
      return notificationTransformer.fetchCollection(data)

    // response.data.forEach(noti => {
    //   if(noti.data){
    //     if (noti.data.type == "sinister")
    //       notifications.push(notificationTransformer.notificationSinister.fetch(noti))
    //     else if (noti.data.type == "sinisters")
    //       notifications.push(notificationTransformer.notificationSinisters.fetch(noti))
    //     else if (noti.data.type == "custom")
    //       notifications.push(notificationTransformer.customNotification.fetch(noti))
    //       else
    //     notifications.push(notificationTransformer.notification.fetch(noti))
    //   }
    //   else
    //     notifications.push(notificationTransformer.notification.fetch(noti))
    //
    // });
    //
    //
    // // success(notifications)
    // return notifications
    }).catch((error) => {
      console.log(error)
    })
}
