<template>
  <v-container class="container--fluid fill-height login-container grey lighten-2">
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="8" md="4" lg="4">
        <v-card class="elevation-12 pa-3">
          <v-card-text>
            <div class="layout column align-center">
              <v-avatar size="192px" tile>
                <v-img :src="logo">
                </v-img>
              </v-avatar>
              <v-list-item dense>
                <v-list-item-content>
                  <h2 class="text-left mt-n2 my-4 primary--text">
                    {{ $appName }}
                  </h2>
                  <h4 class="title-2 text--black mt-n2">
                    Siniestros Autos
                  </h4>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="primary" x-large>mdi-car-traction-control</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </div>
            <v-form id="login-form" ref="loginForm" @submit.prevent="login" v-model="validLogin">
              <v-text-field
                v-model="username"
                prepend-icon="mdi-account"
                data-test="username"
                name="username"
                label="Usuario"
                type="email"
                required
                autocomplete="username"
              />
              <v-text-field
                v-model="password"
                prepend-icon="mdi-lock"
                name="password"
                data-test="password"
                label="Contraseña"
                type="password"
                required
                autocomplete="current-password"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              dark
              rounded
              text
              color="black" :loading="loading" type="submit" form="login-form">
              <v-icon> mdi-login-variant </v-icon>
              Iniciar sesión
            </v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="showLoginError" color="error">{{ loginError }}</v-snackbar>
  </v-container>
</template>

<script>
import authService from '@/services/auth'
import logo from '@/assets/logo.png'

const NO_RESPONSE_ERROR = 'No se pudo conectar con el servidor'
const GENERIC_ERROR = 'Error tratando de iniciar sesión'
const DEFAULT_ROUTE = {
  name: 'Dashboard'
}
/**
 * Kraken App Login
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 */
export default {
  name: 'Login',

  methods: {
    login() {
      const { validLogin } = this

      if (!validLogin) {
        this.$refs.loginForm.validate()
        return
      }

      const { username, password } = this

      this.loading = true
      // Example service data json to login.js /services
      authService
        .login({ username, password })
        .then(() => {
          const { nextURL } = this.$route.query
          const route = nextURL ? { path: nextURL } : DEFAULT_ROUTE
          this.$router.push(route)
        })
        .catch(err => {
          this.showLoginError = true

          const { response } = err.wrapped ? err.wrapped : err

          let loginError
          if (!response) {
            loginError = NO_RESPONSE_ERROR
          } else if (response.status === 400) {
            const { non_field_errors: errors } = response.data
            loginError = errors ? errors[0] : GENERIC_ERROR
          } else {
            loginError = err
          }

          this.loginError = loginError
        })
        .finally(() => {
          this.loading = false
        })
    }
  },

  data() {
    return {
      logo,
      loading: null,
      username: null,
      password: null,
      showLoginError: false,
      loginError: null,
      validLogin: null,
      requiredRules: [v => !!v || 'Este campo es requerido']
    }
  }
}
</script>

<style lang="css" scoped>
.aurora-logo {
  font-size: 80px !important;
}

.login-container {
  background: url("./../assets/login_bg.jpg") no-repeat center center fixed;
  background-size: cover;
}
</style>
