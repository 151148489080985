/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import Vue from 'vue'
import { STORE, UPDATE, CHECK, UNLOAD } from './mutation-types'

export default {
  [CHECK](state) {
    let accountData = Vue.$storage.getItem('account')
    accountData = accountData ? JSON.parse(atob(accountData)) : null
    state.accountLoaded = accountData
  },

  [UNLOAD](state) {
    state.accountLoaded = null
  },

  [STORE](state, account) {
    // state.address = account.address
    state.userId = account.userId
    state.username = account.username
    state.treatment = account.treatment
    state.firstName = account.firstName
    state.lastName = account.lastName
    state.secondLastName = account.secondLastName
    state.email = account.email
    // Profile information
    state.profileId = account.profileId
    state.avatar = account.avatar
    state.phone = account.phone
    state.phoneExt = account.phoneExt
    state.cellphone = account.cellphone
    state.client = account.client
    // state.alternativeEmail = account.alternativeEmail
    // Groups and permissions
    // state.userArea = account.userArea
    // state.userRole = account.userRole
    // state.userRoleCode = account.userRoleCode
    // state.userType = account.userType
    state.groups = account.groups
    state.permissions = account.permissions
    state.provider = account.provider
    // Vue.$storage.setItem('account', btoa(JSON.stringify(account)))
  },

  [UPDATE](state, account) {
    state.userId = account.userId
    state.username = account.username
    state.treatment = account.treatment
    state.firstName = account.firstName
    state.lastName = account.lastName
    state.secondLastName = account.secondLastName
    state.email = account.email
    // Profile information
    state.profileId = account.profileId
    state.avatar = account.avatar
    state.phone = account.phone
    state.phoneExt = account.phoneExt
    state.cellphone = account.cellphone
    // Groups and permissions
    // state.userArea = account.userArea
    // state.userRole = account.userRole
    // state.userRoleCode = account.userRoleCode
    // state.userType = account.userType
    state.groups = account.groups
    state.permissions = account.permissions
    state.provider = account.provider
  }
}
