import Vue from 'vue'
import reportChannelTransfomer from '@/transformers/sinister/reportChannel'

/**
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 *
 *
 * Fetch report modes list
 * @return {Object} report modes list info
 */

export default () =>
  Vue.$http.get('sinister/repport_mode/')
    .then(response => reportChannelTransfomer.fetchCollection(response.data))
