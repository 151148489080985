/**
 * @vuepress
 * ---
 * title: affectedTransformer
 */

import Transformer from '../transformer'
import providerTransform from '@/transformers/sinister/assignation'

class onlyAffectedTransformer extends Transformer {
  static fetch(affected) {
    return {
      'id': affected.id,
      'processAbbre': affected.process.abbreviation,
      'process': affected.process,
      'name': affected.name,
      'contact': affected.contact,
      'contactphones': affected.contact_phones,
      'description': affected.description,
      'damageorigen': affected.damage_origin,
      'checkinLat': affected.checkin_lat,
      'checkinLon': affected.checkin_lon,
      'contactDate': affected.contact_date,
      'checkinUser': affected.checkin_user,
      'checkinFullName': `${affected.checkin_user ? affected.checkin_user.user.first_name + ' ' + affected.checkin_user.user.last_name : affected.checkin_user}`,
      'checkinDate': affected.checkin_date,
      'providerAttention': providerTransform.fetchCollection(affected.provider4attention),
      'scopeMotifs': affected.scope_motifs
    }
  }
}
export default onlyAffectedTransformer
