import Vue from 'vue'
import RegisterSinisterTransformer from '@/transformers/sinister/sinister'

/**
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 *
 * @param slug sinister's slug
 * @param payload sinister's info
 *
 * Update sinisters or sinister
 * @return {Object} sinister update list info
 */

export default ({ slug, ...payload }) =>
  Vue.$http.put(`sinister/${slug}/`, RegisterSinisterTransformer.send(payload))
    .then(res => res.data)
