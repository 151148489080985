import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'Proveedores',
    component: Layout,
    meta: {
      showInSidebar: true,
      icon: 'mdi-account-network',
      excludeGroup: EXTRANET_GROUPS,
      ifCan: {
        view: 'providers/provider'
      }
    },
    children: [
      {
        path: '/proveedores/listado',
        name: 'Provider List',
        component: () => import('@/pages/Providers/Listing'),
        meta: {
          title: 'Listado',
          showInSidebar: true,
          // icon: 'mdi-format-list-bulleted',
          forceRefresh: true
        }
      },
      {
        path: '/proveedores/cuentas',
        name: 'Provider Accounts',
        component: () => import('@/pages/Providers/Accounts'),
        meta: {
          title: 'Cuentas',
          showInSidebar: true,
          // icon: 'mdi-format-list-bulleted',
          forceRefresh: true,
          ifCan: {
            view: 'providers/advancesinvoice'
          }
        }
      },
      {
        path: '/proveedores/cuentastotales',
        name: 'Total Provider Accounts',
        component: () => import('@/pages/Providers/TotalAccounts'),
        meta: {
          title: 'Cuentas totales',
          showInSidebar: true,
          forceRefresh: true,
          ifCan: {
            view: 'providers/advancesinvoice'
          }
        }
      },
      {
        path: '/proveedores/nuevo',
        name: 'Provider Create',
        component: () => import('@/pages/Providers/Register/'),
        meta: {
          showInSidebar: true,
          title: 'Registrar proveedor',
          // icon: 'mdi-card-bulleted',
          ifCan: {
            add: 'providers/provider'
          }
        }
      },
      {
        path: '/proveedores/editar/:providerSlug',
        name: 'Provider Edit',
        props: true,
        component: () => import('@/pages/Providers/Register/'),
        meta: {
          showInSidebar: false,
          title: 'Editar proveedor',
          icon: 'mdi-card-bulleted',
          ifCan: {
            change: 'providers/provider'
          }
        }
      },
      {
        path: '/proveedores/:providerSlug',
        name: 'Provider File',
        props: true,
        component: () => import('@/pages/Providers/File/'),
        meta: {
          title: 'Ficha de proveedor',
          icon: 'mdi-card-bulleted'
        }
      }
    ]
  }
]
