
import Vue from 'vue'

export default (router) => {
  router.beforeEach((to, from, next) => {
    const routeName = to.meta && to.meta.title ? `${to.meta.title} - ` : ''
    document.title = routeName + (Vue.prototype.$appName || 'Aurora 7')
    next()
  })
}
