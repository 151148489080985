
import Vue from 'vue'

/**
 * @author Jorge Nuñez L. <>
 *
 * @param slug zone's slug
 * @param codename customer's codename
 *
 * Fetch sinisters's authorization matrix
 * @return {Object}
 */

export default (zone = '', customer = '') => {
  return Vue.$http.get(`customer/matrix/${zone}/${customer}/`)
}
