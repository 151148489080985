/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import * as types from './mutation-types'

export const store = ({ commit }, payload) => {
  commit(types.STORE, payload)
}

export const create = ({ commit }, payload) => {
  commit(types.CREATE, payload)
}

export const update = ({ commit }, payload) => {
  commit(types.UPDATE, payload)
}
export const del = ({ commit }, payload) => {
  commit(types.DELETE, payload)
}

export default {
  create,
  update,
  store,
  delete: del
}
