/** Global Components
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 */

import NoContent from './components/NoContent'
import PrettyPrint from './components/PrettyPrint'

export default {
  install(Vue) {
    Vue.component('no-content', NoContent)
    Vue.component('pretty-print', PrettyPrint)
  }
}
