import Vue from 'vue'

/**
 * @author Mariela Temozihui TL. <mariela.temozihui@jbge.com.mx>
 *
 * @param slug Sinister
 *
 */

export default (slug) =>
  Vue.$http.get(`sinister/sendemail/assignment/${slug}/`)
    .then(res => res.data)
