
import Transformer from '@/transformers/transformer'
import transformPermissions from './permissions'

class GroupTransformer extends Transformer {
  static fetch ({ name, permissions }) {
    return {
      name,
      permissions: transformPermissions(permissions)
    }
  }
}

export default GroupTransformer
