import copyToClipboard from "./copyToClipboard";

const copyData = (data, headers) => {
  const headerText = headers.map(({ text }) => text).join("\t");
  const textData = data
    .map((item) =>
      headers
        .map(({ value }) => value)
        .map((h) => item[h])
        .join("\t")
    )
    .join("\n");

  return copyToClipboard(`${headerText}\n${textData}`);
};

export default copyData;
