import Vue from 'vue'
import casualtyTransformer from '@/transformers/sinister/affected'

/**
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 *
 * @param sinister Sinister's slug
 * @param casualty casualty's info to be sent
 *
 * Updates an exiting casualty
 * @return {Object} casualty info
 */

export default (sinister, casualty, payload) =>
  Vue.$http.put(`sinister/detail/${sinister}/affected/${casualty}/`, casualtyTransformer.update(payload))
    .then((res) => res.data)
