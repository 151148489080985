
import login from './login'
import logout from './logout'
import refreshToken from './refreshToken'
import verifyToken from './verifyToken'

/**
 * auth services
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 */
export default {
  login,
  logout,
  refreshToken,
  verifyToken
}
