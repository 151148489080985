
import store from '@/store'

/**
 * Logout user from app
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 * @return {Promise} Logout action dispatch
 */
const logout = () =>
  Promise.resolve().then(() =>
    store.dispatch('auth/logout'))

export default logout
