
import Transformer from '@/transformers/transformer'

class ProcessTransformer extends Transformer {
  static fetch({
    id, slug, name, color,
    number, abbreviation,
    hide_in_macro: hideInMacro
  }) {
    return {
      id,
      slug,
      name,
      number: +number,
      level: +number.toString().split('.')[1],
      abbreviation,
      code: abbreviation,
      color,
      hideInMacro
    }
  }
}

class BusinessCycleTransformer extends Transformer {
  static fetch({ status, color, name, slug }) {
    const processes = ProcessTransformer
      .fetchCollection(status)
    processes
      .sort(({ level: level1 }, { level: level2 }) => level1 > level2)
    const visibleProcesses = processes
      .filter(({ hideInMacro }) => !hideInMacro)
    return {
      processes,
      visibleProcesses,
      color,
      name,
      slug
    }
  }
}

export default BusinessCycleTransformer
