import fetch from './fetch'
import fetchCollection from './fetchCollection'
import create from './create'
import update from './update'
import del from './delete'

export {
  fetch,
  fetchCollection,
  update,
  create,
  del
}

export default {
  fetch,
  create,
  update,
  delete: del,
  view: fetch,
  add: create,
  change: update
}
