
import Vue from 'vue'

function transformer({
  conversation, ai_response: response, userInput, ai_response_html: responseHtml,
}) {
  return {
    response: response,
    responseHtml: responseHtml,
	  userInput,
    conversation
  }
}

export default ({ text, conversation }) =>
  Vue.$http.post('avi_chat/send_message/', { user_input: text.trim(), conversation })
    .then(response => transformer(response.data))
