
import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'Clientes',
    component: Layout,
    meta: {
      showInSidebar: true,
      icon: 'mdi-clipboard-account',
      excludeGroup: EXTRANET_GROUPS,
      ifCan: {
        view: 'clients/customer'
      }
    },
    children: [
      {
        path: '/clientes/listado',
        name: 'Customers List',
        component: () => import('@/pages/catalogs/customers/listing/index.vue'),
        meta: {
          title: 'Lista de clientes',
          showInSidebar: true,
          forceRefresh: true
        }
      },
      {
        path: '/clientes/edicion/:id',
        name: 'Edit Customer',
        props: true,
        component: () => import('@/pages/catalogs/customers/form/index.vue'),
        meta: {
          title: 'Editar cliente',
          showInSidebar: false,
          ifCan: {
            change: 'clients/customer'
          }
        }
      },
      {
        path: '/clientes/registro',
        name: 'Create Customer',
        component: () => import('@/pages/catalogs/customers/form/index.vue'),
        meta: {
          title: 'Crear cliente',
          showInSidebar: true,
          ifCan: {
            add: 'clients/customer'
          }
        }
      },
      {
        path: '/clientes/detalles/:id',
        name: 'Customer Details',
        props: true,
        component: () => import('@/pages/catalogs/customers/details/index.vue'),
        meta: {
          title: 'Detalles de cliente'
        }
      },
      {
        path: '/clientes/auditores',
        name: 'Auditors List',
        component: () => import('@/pages/catalogs/auditors/index.vue'),
        meta: {
          title: 'Auditores',
          showInSidebar: true,
          forceRefresh: true
        }
      },
      {
        path: '/clientes/detalles/auditor/:id',
        name: 'Auditor Details',
        props: true,
        component: () => import('@/pages/catalogs/customers/details/index.vue'),
        meta: {
          title: 'Detalles de auditor'

        }
      },
      {
        path: '/clientes/personal',
        name: 'Personal List',
        component: () => import('@/pages/catalogs/staff/index.vue'),
        meta: {
          title: 'Personal',
          showInSidebar: true,
          ifCan: {
            view: 'clients/customerstaff'
          }
        }
      },
      {
        path: '/clientes/ajustadores',
        name: 'Adjusters',
        component: () => import('@/pages/catalogs/adjusters/index.vue'),
        meta: {
          title: 'Ajustadores',
          showInSidebar: true,
          ifCan: {
            view: 'clients/adjusters'
          }
        }
      },
      {
        path: '/clientes/autorizados',
        name: 'Authorized Customer',
        component: () => import('@/pages/catalogs/customers/authorization/index.vue'),
        meta: {
          title: 'Autorización de clientes',
          showInSidebar: true,
          ifCan: {
            view: 'clients/authorizationmatrix'
          }
        }
      }
    ]
  }
]
