import Vue from 'vue'
import errorHandler from '@/utils/errorHandler'

export default (url, fileName, opts = {}) => {
  const config = opts.config || {}
  return errorHandler(Vue.$http({
    url,
    method: 'GET',
    responseType: 'blob',
    ...config
  }))
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
}
