
// import Notification from './notification'
// import NotificationSinister from './notificationSinister'
// import NotificationSinisters from './notificationSinisters'
// import CustomNotification from './customNotification'
import NotificationTransformer from './notificationMapper'
//
// export {
// notification: Notification,
// notificationSinister: NotificationSinister,
// notificationSinisters: NotificationSinisters,
// customNotification: CustomNotification,
// }
//
export default NotificationTransformer
