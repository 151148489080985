import Vue from "vue";
import store from "@/store";

const saveInStore = (urlGroup, catalog, data, storeName = "catalogs") => {
  store.dispatch(`${storeName}/store`, { catalog, data, urlGroup });
};

/**
 * Fetches a catalog list or item from API
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 * @param  {String}         catalogURL The catalog endpoint
 * @param  {Object}         opts       Options
 * @param  {String}         opts.urlGroup    The URL urlGroup/namespace
 * @param  {String|Number}  opts.id          Fetch an specific item
 * @param  {Object}         opts.config      Extra configuration for axios
 * @param  {Boolean}        opts.collection  Force a collection fetch
 * @param  {Boolean}        opts.paginated   Fetch paginated data
 * @param  {Object}         opts.transformer Use a specific transformer
 * @param  {Boolean}        opts.useStore    Save data in vuex store
 * @return {Promise}        An http request promise
 */
const catalogFetch = (catalogURL, opts = {}) => {
  if (typeof opts !== "object") {
    opts = { id: opts };
  }

  const {
    id,
    config,
    collection,
    paginated,
    useTransformer,
    useStore,
    urlGroup,
    storeAs,
  } = opts;
  const urlPrefix = urlGroup ? `${urlGroup}/` : "";

  let url = `/${urlPrefix}${catalogURL}/`;

  if (id) {
    url += `${id}/`;
  }

  let fetchDetail = (!collection && !!id) || collection === false;

  return Vue.$http.get(url, config).then((response) => {
    const transformer = useTransformer;
    let data = response.data;
    let paginationInfo;

    if (paginated) {
      const { results, ...pagInfo } = data;
      data = results;
      paginationInfo = pagInfo;
    }

    if (transformer && transformer.fetch) {
      data = fetchDetail
        ? transformer.fetch(data)
        : transformer.fetchCollection(data);
    }

    if (!fetchDetail && useStore) {
      const storeName = typeof useStore === "string" ? useStore : "catalogs";
      saveInStore(storeAs || urlGroup, catalogURL, data, storeName);
    }

    if (!paginationInfo) {
      return data;
    } else {
      return { results: data, paginationInfo };
    }
  });
};

export default catalogFetch;
