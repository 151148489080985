<template>
  <div>
    <v-list expand dense nav>
      <menu-route-item
        :route="route"
        v-for="route in allowedRoutes"
        :disabled="route.disabled"
        :key="route.path"
      />
    </v-list>
  </div>
</template>

<script>
import { flatten } from 'lodash'
import MenuRouteItem from './MenuRouteItem'

const showRouteInSidebar = (route) =>
  !!(route.showInSidebar || (route.meta && route.meta.showInSidebar))

function hasChilds(route) {
  if (!route.children) {
    return false
  }
  return route.children.some(showRouteInSidebar)
}

let routeItem
routeItem = ({ meta = {}, children, path, name }) => ({
  icon: meta.icon,
  ifCan: meta.ifCan,
  ifInGroup: meta.ifInGroup,
  excludeGroup: meta.excludeGroup,
  text: meta.title || name,
  children: children
    ? children.filter(showRouteInSidebar).map(routeItem)
    : undefined,
  to: path,
  subtext: meta.subtitle
})

export default {
  name: 'MenuRoutes',

  components: {
    MenuRouteItem
  },

  data() {
    const developmentMode = process.env.NODE_ENV === 'development'
    return {
      developmentMode
    }
  },

  methods: {
    hasPermission(route) {
      const { ifCan, ifInGroup, excludeGroup } = route
      if (!ifCan && !ifInGroup && !excludeGroup) {
        return true
      }

      if (ifCan) {
        const entries = Object.entries(ifCan)

        for (const [permission, entry] of entries) {
          let appModels = entry
          if (!Array.isArray(appModels)) {
            appModels = [appModels]
          }

          appModels = appModels.map((s) => s.split('/'))

          for (const [app, model] of appModels) {
            if (!this.$can(permission, app, model)) {
              return false
            }
          }
        }
      }

      if (ifInGroup) {
        const groupsToCheck = Array.isArray(ifInGroup) ? ifInGroup : [ifInGroup]
        const belongsToAGroup = this.$inGroup(...groupsToCheck)
        if (!belongsToAGroup) {
          return false
        }
      }
      const groups = Array.isArray(excludeGroup) ? excludeGroup : [excludeGroup]
      for (const group of groups) {
        if (this.$inGroup(group)) {
          return false
        }
      }
      return true
    }
  },

  computed: {
    routes() {
      const parentRoutes = this.$router.options.routes
        .filter((route) => !!(route.meta && route.meta.showInSidebar))
        .map(routeItem)

      const orphan = flatten(
        this.$router.options.routes
          .filter((route) => !(route.meta && route.meta.showInSidebar))
          .filter(hasChilds)
          .map((r) => r.children.filter(showRouteInSidebar).map(routeItem))
      )

      return [...orphan, ...parentRoutes]
    },

    allowedRoutes() {
      return this.routes
        .map(({ children, ...route }) => {
          return {
            ...route,
            disabled: !this.hasPermission(route),
            children:
              children &&
              children
                .map((c) => ({
                  ...c,
                  disabled: !this.hasPermission(c)
                }))
                .filter(({ disabled }) => !disabled)
          }
        })
        .filter(({ disabled }) => !disabled)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
