/**
 * @vuepress
 * ---
 * title: Zone
 * ---
 */
/**
 * @class zoneTransformer
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 */

import Transformer from '../transformer'

class zoneTransformer extends Transformer {
  /**
   * Transform fetched zone info
   *
   * @param zone The fetched zone
   *
   * @returns {Object} The transformed zone
   */
  static fetch(zone) {
    const state = { ...zone.state }
    return {
      'id': zone.id,
      'state': state && state.id,
      'stateName': state && state.name,
      'isActive': zone.is_active,
      'slug': zone.slug,
      'name': zone.name,
      'shortName': zone.short_name,
      'iva': `${parseFloat(zone.iva).toFixed(2)}`,
      'applyIva': zone.apply_iva,
      'CLAIMS': zone.CLAIMS,
      'SISA': zone.SISA,
      'QUA': zone.provider_number_QUA
    }
  }
  /**
   * Transform zone info to be sent.
   *
   * @param zone The zone to be sent.
   *
   * @returns {Object} The transformed zone
   */
  static send(zone) {
    return {
      'name': zone.name,
      'short_name': zone.shortName,
      'CLAIMS': zone.CLAIMS,
      'SISA': zone.SISA,
      'provider_number_QUA': zone.QUA,
      'iva': zone.iva,
      'state': zone.state
    }
  }
  /**
   * Update zone data
   * @param zone The zone to be updated.
   * @returns {Object} The transformed zone
   */
  static update(zone) {
    return {
      'name': zone.name,
      'short_name': zone.shortName,
      'CLAIMS': zone.CLAIMS,
      'SISA': zone.SISA,
      'provider_number_QUA': zone.QUA,
      'iva': zone.iva,
      'state': zone.state
    }
  }
}

export default zoneTransformer
