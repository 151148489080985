
import Vue from 'vue'
import store from './../../store'

const success = function (payload) {
  store.dispatch('catalogs/update', payload)
}

const updateSource = function (source, item, original) {
  if (!source) {
    console.error(source)
    return
  }

  let index = source.indexOf(item)

  if (index !== -1) {
    source.splice(index, 1)
  } else {
    console.error('Could not update source collection.')
  }
}

/**
 * Updates a catalog item in API
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 * @param  {String}  catalogURL The catalog url
 * @param  {Object}  catalog    The updated data
 * @param  {Object=} oldCatalog The current instance (optional)
 * @param  {Object}  config     Extra configuration for axios
 * @return {Promise}            An axios promise to update a catalog item
 */
const updateCatalog = (catalogURL, catalog, oldCatalog, config = {}) => {
  const { useTransformer, urlGroup } = config
  let payload = catalog
  const transformer = useTransformer

  if (transformer && transformer.send) {
    payload = transformer.send(payload)
  }

  let id = payload.slug || payload.id || config.id

  if (payload instanceof FormData) {
    config.headers = {
      'content-type': 'multipart/form-data'
    }
    id = payload.get('id')
  }

  let useStore = config.useStore
  config.useStore = null

  let source = config.source
  config.source = null

  const urlGroupURL = urlGroup ? `${urlGroup}/` : ''
  let url = `/${urlGroupURL}${catalogURL}/${id}/`

  let request = Vue.$http.put(url, payload, config)

  return request.then(response => {
    let data = response.data

    if (transformer && transformer.fetch) {
      data = transformer.fetch(data)
    }

    if (useStore) {
      success({ type: catalogURL, data, old_data: oldCatalog })
    }

    if (source) {
      updateSource(source, catalog, oldCatalog)
    }

    return data
  })
}

export default updateCatalog
