<template>
  <div class="v-application--wrap grey lighten-4">
    <the-layout-drawer />
    <the-layout-toolbar />
    <the-layout-content/>

    <AVIChat
	v-if="$can('avi_login', 'AVIChat', 'avitoken')"
    />
    <the-layout-to-top-fab />
    <the-layout-footer />
  </div>
</template>

<script>
import TheLayoutDrawer from "./TheLayoutDrawer.vue";
import TheLayoutToolbar from "./TheLayoutToolbar.vue";
import TheLayoutContent from "./TheLayoutContent.vue";
// import TheLayoutFab from './TheLayoutFab.vue'
import AVIChat from '@/components/AVIChat.vue'
import TheLayoutToTopFab from "./TheLayoutToTopFab.vue";
import TheLayoutFooter from "./TheLayoutFooter.vue";

const developmentMode = process.env.NODE_ENV === "development";
export default {
  name: "TheLayout",
  components: {
    TheLayoutDrawer,
    TheLayoutToolbar,
    TheLayoutContent,
    // TheLayoutFab,
    TheLayoutToTopFab,
    AVIChat,
    TheLayoutFooter,
  },
  data: () => ({ developmentMode }),
};
</script>

<style>
.v-navigation-drawer--fixed {
  -webkit-box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
</style>
