function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.position = "fixed"; // avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  var successful;

  try {
    successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);

  return successful;
}

function copyTextToClipboard(text) {
  return new Promise((resolve, reject) => {
    console.log("copying text", { text });
    if (!navigator.clipboard) {
      if (fallbackCopyTextToClipboard(text)) {
        resolve();
      } else {
        reject(new Error("Copy to Clipboard Error"));
      }
      return;
    }

    const clipboardAPI = navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Async: Copying to clipboard was successful!");
        resolve();
      })
      .catch((err) => {
        const msg = "Async: Could not copy text: ";
        console.error(msg, err);
        reject(err);
      });

    setTimeout(async () => await clipboardAPI, 0);
  });
}

export default copyTextToClipboard;
