
const icons = {
  add: 'mdi-plus-circle',
  adjuster: 'mdi-account-tie-outline',
  advance: 'mdi-hexagon-multiple',
  auditor: 'mdi-account-tie-voice',
  casualty: 'mdi-car-child-seat',
  client: 'mdi-office-building',
  close: 'mdi-close',
  concept: 'mdi-widgets-outline',
  customer: 'mdi-office-building',
  customerSupervisor: 'mdi-account-tie',
  delete: 'mdi-delete',
  division: 'mdi-account-group',
  edit: 'mdi-pencil',
  email: 'mdi-mail-ru',
  filter: 'mdi-filter-variant',
  image: 'mdi-image',
  info: 'mdi-information',
  jobType: 'mdi-worker',
  price: 'mdi-coin',
  provider: 'mdi-clipboard-account',
  scope: 'mdi-calendar-range',
  sinister: 'mdi-car',
  staff: 'mdi-account-multiple',
  supervisor: 'mdi-account-tie-outline',
  supply: 'mdi-dolly',
  udn: 'mdi-briefcase',
  zone: 'mdi-map-marker-radius'
}

export default (status) => {
  return icons[status] || ''
}
