/**
 * @vuepress
 * ---
 * title: Account store getters
 * headline: Getters to facilitate account info checking
 * ---
 */

/**
 * Getters for the account module
 *
 * The getters that are available on the
 * account module.
 */

const NO_GROUP = {
  name: 'N/G'
}

export default {
  userId: (state) => state.userId,

  name: (state) =>
    `${state.firstName} ${state.lastName} ${state.secondLastName}`,

  modelPermission: (state) => (app, model) => {
    const appD = state.permissions[app] || {}
    return appD[model] || {}
  },

  userGroup: (state) => state.groups && state.groups.length ? state.groups[0] : NO_GROUP,

  /**
   * Check if user belong to a specific group
   */
  userInGroup: (state) => (...groups) =>
    state.groups.some(({ name }) => groups.some(gr => name === gr)),

  /**
   * Check if user belongs to the Admin group
   */
  userIsAdmin: (state) => state.groups.some(g => g.name === 'Admin'),

  /**
   * Checks if user has a given permission in an app/model
   */
  userCan: (state, getters) => (permission, app, model) => {
    return getters.modelPermission(app, model)[permission] || false
  },

  /**
   * Shortcut for userCan('add')
   */
  userCanAdd: (state, getters) =>
    (app, model) => getters.userCan('add', app, model),
  /**
   * Shortcut for userCan('view')
   */
  userCanView: (state, getters) =>
    (app, model) => getters.userCan('view', app, model),
  /**
   * Shortcut for userCan('change')
   */
  userCanChange: (state, getters) =>
    (app, model) => getters.userCan('change', app, model),
  /**
   * Shortcut for userCan('delete')
   */
  userCanDelete: (state, getters) =>
    (app, model) => getters.userCan('delete', app, model)
}
