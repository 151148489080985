<template>
  <v-navigation-drawer
    :class="drawerClass"
    fixed
    app
    :value="navbarShow"
    width="250"
    @input="stateNavbarShow"
  >
    <v-list-item dense :to="{
        name: 'Dashboard'
      }">
      <v-list-item-avatar size="36px" class="ml-n1" tile>
        <img :src="logo" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <span class="hidden-sm-and-down title text--darken-2 grey--text">{{ $appName }}</span>
          <strong class="deep-orange--text text--darken-1 subtitle-2" v-if="developmentMode">DEV</strong>
        </v-list-item-title>
        <v-list-item-subtitle class="mt-n2">
          <strong class="primary--text text--darken-2">Siniestros Autos</strong>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <menu-routes />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuRoutes from '@/components/MenuRoutes'
import logo from '@/assets/logo.png'

const PRODUCTION = process.env.NODE_ENV === 'production'

export default {
  name: 'TheLayoutDrawer',
  components: {
    MenuRoutes
  },
  data: () => ({
    developmentMode: !PRODUCTION,
    drawerClass: PRODUCTION
      ? 'grey lighten-3 drawer-container elevation-2'
      : 'deep-orange lighten-2 dev-drawer-container elevation-2',
    logo
  }),
  computed: {
    ...mapGetters([
      'permissionRoutes',
      'navbarDense',
      'navbarShow',
      'navbarLogo',
      'toolbarDense'
    ])
  },
  methods: {
    stateNavbarShow(state) {
      this.$store.dispatch('NavbarState', { state })
    }
  }
}
</script>

<style>
.drawer-container {
  background: url("./../assets/sidebar_bg.jpg") no-repeat center center fixed;
  background-size: cover;
}
.dev-drawer-container {
  background: #fffbd5;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #b2262b 10%, #fffbd5);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #b2262b, #fffbd5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-size: cover;
}
</style>
