<template functional>
  <v-sheet class="overflow-y-auto" style="max-height: 256px;">
    <pre>
      {{ JSON.stringify(data.attrs, null, 2) }}
    </pre>
  </v-sheet>
</template>

<script>
/***
  * Prints a value to with identation
  * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
*/

export default {
  inheritAttrs: false,

}
</script>

<style>

</style>
