/**
 * @vuepress
 * ---
 * title: Routes Auth & Permissions
 * ---
 */

import store from '@/store'
import accountService from '@/services/account'
import authService from '@/services/auth'
import { routerSetup } from '@/plugins/permissions'

/**
 * Setups router auth check guards
 * @param  {router} router App router
 */

const routerPermission = (router) => {
  router.beforeEach((to, from, next) => {
    store.dispatch('auth/check')
    const authenticated = store.state.auth.authenticated
    const { userId } = store.state.account

    if (to.name === 'Login') {
      if (authenticated) {
        return next({ name: 'Dashboard' })
      } else {
        return next()
      }
    } else if (!authenticated) {
      return next({
        name: 'Login',
        query: { nextURL: to.fullPath }
      })
    }
    let checkAuth
    if (!userId) {
      checkAuth = authService.verifyToken()
        .then(authService.refreshToken)
        .then(() => {
          store.dispatch('account/check')
          const { accountLoaded } = store.state.account
          if (!accountLoaded) {
            return accountService.fetch()
          } else {
            store.dispatch('account/load')
            return Promise.resolve(accountLoaded)
          }
        })
    } else {
      checkAuth = authService.refreshToken()
    }

    checkAuth.then(() => next())
      .catch(err => {
        console.error('auth error', err)
        authService.logout()
          .then(() => {
            next({
              name: 'Login',
              query: { nextURL: to.fullPath }
            })
          })
      })
  })
  routerSetup(router)
}

export default routerPermission
