/**
 * @vuepress
 * ---
 * title: provider transformer
 */

import Transformer from '../transformer'
import zoneTransformer from '@/transformers/catalogs/zones'

const userFullName = ({ name, last_name: lastName, mothers_last_name: secondLastName }) =>
  `${name || ''} ${lastName || ''} ${secondLastName || ''}`

class providerTransformer extends Transformer {
  /**
   * @author José Luis Ruiz
   */

  static fetch(provider) {
    return {
      'fullName': provider.name ? userFullName(provider) : '',
      'commercialName': provider.commercial_name || '',
      'curp': provider.curp,
      'doesBill': provider.does_bill,
      'providerNumber': provider.provider_number,
      'mainFamily': provider.main_family ? provider.main_family.codename : '',
      'familySlug': provider.main_family ? provider.main_family.slug : '',
      'rfc': provider.rfc,
      'isActive': provider.is_active,
      'servicesZone': provider.services_zone ? zoneTransformer.fetchCollection(provider.services_zone) : [],
      'slug': provider.slug
    }
  }
}
export default providerTransformer
