<script setup>
/**
 * SupportChat - A support chat component
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 * @version 1.0
 */

import { sendMessage, login } from '@/services/chat'
import { copyToClipboard } from '@/utils'

import { onMounted, ref, computed, watch } from 'vue'
import { flatten } from 'lodash'

const DISPLAY_TIMEOUT = 500

const userMessage = ref('')
const loading = ref(false)
const userInput = ref(null)
const loggedIn = ref(false)
const chatWidget = ref({ name: 'housto' })
const chatConversation = ref([])
const chatPanelBtn = ref(true)
const chatPanelShow = ref(false)
const tooltipText = ref()
const showTooltip = ref()
const isDev = process.env.NODE_ENV === 'development'

const stateChatPanel = () => {
  console.log('stateChatPanel')
}

const toggleChatPanel = () => {
  chatPanelShow.value = !chatPanelShow.value
	if (!loggedIn.value) {
		chatLogin()
	}
}

const newEntries = computed(() =>
  flatten(
    chatConversation.value.map(({ ai, human }) =>
      [{ text: human, user: true }, { text: ai, ai: true }]
    )
  )
)

function hideMenu() {
  chatPanelShow.value = false
}

function SendMessage() {
  const text = userMessage.value
  // chatConversation.value.push({ human: `${text}` })
  userMessage.value = ''
  loading.value = true
  sendMessage({ text, conversation: chatConversation.value })
    .then(({ response, message, userInput, conversation, responseHtml }) => {
      console.log('Conversation', { conversation, response, responseHtml })
	    chatConversation.value.push({
		    ai: responseHtml || response,
		    aiRaw: response,
		    human: text
	    })
    })
    .catch(err => {
      console.log("AVI Request Error", { err })
    })
    .finally(() => {
      loading.value = false
    })
}

function focusTextArea() {
  setTimeout(() => {
    console.log('Trying to focus text', { userInput })
    userInput.value.focus()
  }, 100)
}

function chatLogin() {
  return login()
    .then(({ widget, user, ...data }) => {
      loggedIn.value = true
      console.log('Logged in', { widget, user, data })
      chatWidget.value = widget
    })
}

function resetChat() {
  chatConversation.value = []
  hideMenu()
  setTimeout(() => { chatPanelShow.value = true }, 500)
}

onMounted(() => {
  chatLogin()
})

watch(chatPanelShow, async () => {
  focusTextArea()
})

watch(chatWidget, async (value) => {
	const { message } = value
	console.log(message)
	if (!chatPanelShow.value) {
		tooltipText.value = message
		showTooltip.value = true
		setTimeout(() => showTooltip.value = false, 6000)
	}
})

const copyText = (text) => {
	copyToClipboard(text)
}
</script>

<template>
  <v-content class="layout-fab">
    <v-tooltip top v-model="showTooltip" color="accent darken-3" max-width="400px">
	    <template v-slot:activator="{ on, attrs }">
		    <v-btn
		      v-if="chatPanelBtn"
		      small
		      fab
		      falt
		      fixed
		      :disabled="!loggedIn && !isDev"
		      top="top"
		      right="right"
		      :color="loggedIn ? 'accent darken-1' : 'warning'"
		      class="layout-fab__btn noprint"
		      v-on="on"
		      v-bind="attrs"
		      @click="toggleChatPanel"
		    >
		      <v-icon>
			{{ loggedIn ? 'mdi-robot' : 'mdi-robot-dead-outline' }}
		      </v-icon>
		    </v-btn>

	    </template>
	    <p class="text-button">
		    {{ tooltipText }}
	    </p>
    </v-tooltip>
    <v-navigation-drawer
      right
        dense
      disable-resize-watcher
      temporary
      fixed
		    v-model="chatPanelShow"
    >
      <v-app-bar
        :dense="true"
        color="accent darken-1"
        dark
      >
	      <v-app-bar-nav-icon @click="hideMenu">
		      <v-icon> mdi-robot-outline </v-icon>
	      </v-app-bar-nav-icon>
        <v-toolbar-title>
		<h3 class="text-h5 text-uppercase">
			{{ chatWidget.name }}
		</h3>
        </v-toolbar-title>
	<v-spacer/>
            <v-btn
              absolute
              dark
              fab
              bottom
              right
              small
              color="info darken-2"
              @click="resetChat"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
      </v-app-bar>

      <v-subheader class="layout-fab__header">
      </v-subheader>
      <v-divider />
      <v-card>
	      <v-card-text>

	      <v-list density="compact">
		      <v-list-item>
			      <template>
				      <v-icon color="accent"> mdi-robot-happy </v-icon>
			      </template>
			      <p class="chat-text system-text accent--text text--darken-3 font-weight-bold">

			      {{ chatWidget.message }}
			      </p>
		      </v-list-item>
		      <template v-for="{ human, ai, aiRaw }, i in chatConversation">
			      <v-card class="chat-card" :key="i" elevation="0">
				      <v-card-title>
					      <v-spacer/>
					      <v-sheet
						      elevation="2"
						      shaped
						      color="blue-grey lighten-4"
						      rounded
						      class="chat-text text-button user-text pa-2"
						      >
					      	{{ human }}
					      </v-sheet>
				      </v-card-title>
				      <v-card-text>
					      <span class="text-body-1 font-weight-bold accent--text">

						      <v-icon color="accent"> mdi-robot </v-icon>

						      {{ chatWidget.name }}
					      </span>
					      <p class="chat-text system-text"
					      v-html="ai"
					      />
				      </v-card-text>
				      <v-card-actions>
					      <v-spacer/>
					      <v-btn icon x-small class="mt-n12"
						      @click="copyText(aiRaw)">
						      <v-icon>  mdi-content-copy  </v-icon>
					      </v-btn>
				      </v-card-actions>
			      </v-card>
		      </template>
            <v-skeleton-loader
                v-if="loading"
                type="list-item-avatar-three-line"
                loading-text="cargando..."
                height="64px"
                tile
                />
	      </v-list>
	      </v-card-text>
	      <v-card-actions style="margin-top: auto;">
			      <v-textarea
                clearable
                :disabled="loading"
					      v-model="userMessage"
					      name=userInput
					      placeholder="Escribe un mensaje"
					      shaped
					      @keyup.enter="SendMessage"
					      outlined
					      ref="userInput"
                :append-icon="!loading ?  'mdi-chat' : 'mdi-dots-horizontal'"
				      />
	      </v-card-actions>
      </v-card>
    </v-navigation-drawer>
  </v-content>
</template>

<style scoped>
  .layout-fab {
    position: absolute;
  }
  .layout-fab__btn {
    margin-top: 4px; /* fixme: vertical align */
    top: 90% !important;
    right: 0 !important;
    border-radius: 0 !important;
  }
  .layout-fab__switch {
    margin-top: 4px;
  }
  .layout-fab__header {
    padding: 0 12px 0 12px !important;
  }
  .v-expansion-panel-content__wrap {
    padding:0 12px 12px;
  }

  @media print {
    .noprint {
        display: none;
    }
  }
.chat-text {
  text-wrap-mode: wrap;
	display: inline-block;     /* Ensures the background doesn't span the full width */
	word-break: normal;
}
.user-text  {
	text-transform: none !important;
}

.system-text {
}

i.v-icon.chat-icon {
	margin-left: auto;
	margin-right: auto;
}
.chat-entry.v-list-item {
	margin-bottom: 2px;
	line-height: unset;
	height: unset;
	max-height: 1000px;
	min-height: 20px;
}

.progress-container {
	justify-content: center;
}

.menu-chat-content.v-menu-content {
  max-height: 90vh;
}

.chat-card.v-card .v-card__text {
	padding: 0;
}
.chat-card.v-card .v-card__actions {
	padding: 0;
	margin-top: -28;
}

</style>


