// reduce la calidad de la imagen(url)
async function getBase64FromUrl(url, mine, factor = 0.15) {
  var img = new Image()
  url = url.replace('http://', '//')
  img.setAttribute('crossOrigin', 'anonymous')
  return new Promise((resolve, reject) => {
    img.onload = function () {
      var canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      var newImage = canvas.toDataURL(mine || 'image/jpeg', factor)
      resolve(newImage)
    }
    img.src = url
    img.onerror = function() {
      reject(img)
    }
  })
}
export default getBase64FromUrl
