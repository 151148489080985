
export default (imageSrc, factor = 0.6) => {
  return new Promise((resolve) => {
    let canvas = document.createElement('canvas')
    let img = document.createElement('img')
    img.src = imageSrc
    setTimeout(() => {
      canvas.height = img.height
      canvas.width = img.width
      let ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      setTimeout(() => {
        let newImage = canvas.toDataURL('image/jpeg', factor)
        resolve(newImage)
      }, 200)
    }, 200)
  })
}
