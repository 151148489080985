import Vue from 'vue'
import template from './template'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import globalComponents from './plugins/globalComponents'
import notifications from './plugins/notifications'
import storage from './plugins/storage'
import filters from './plugins/filters'
import i18n from './locale' // Internationalization
import { permissionsMixin } from './plugins/permissions'
import sweetAlert from './plugins/sweetAlert'
import 'cropperjs/dist/cropper.css'
import dynamicCatalogs from './plugins/dynamicCatalogs'

import ApiConfig from './apiConfig'

Vue.use(ApiConfig)
Vue.use(storage)
Vue.use(template)
Vue.use(permissionsMixin)
Vue.use(sweetAlert)
Vue.use(notifications)
Vue.use(filters)
Vue.use(dynamicCatalogs)
Vue.use(globalComponents)

Vue.config.productionTip = false
Vue.config.debug = process.env.NODE_ENV !== 'production'

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
