
import Transformer from '@/transformers/transformer'
import notificationTypes from './notificationTypes'
// import {fromNowDate} from '@/filters'
export default class NotificationTransformer extends Transformer {
  static basicFetch({ level, description, timestamp, unread, verb, id, data }) {
    return {
      level, description, timestamp, unread, id, verb, data
    }
  }

  static fetch(notification) {
    const { verb } = notification
    const basicFetch = this.basicFetch(notification)
    const customNoti = notificationTypes.find(({ verb: bVerb }) => verb === bVerb)
    return {
      ...basicFetch,
      ...(customNoti ? customNoti.handler(basicFetch) : {})
    }
  }

  static send(notification) {
  }
}
