/**
 * @vuepress
 * ---
 * title: complaintTransformer
 */

import Transformer from '@/transformers/transformer'
import moment from 'moment'
import 'moment/locale/es-mx'

/**
 * @class invoiceTransformer
 * Invoice transformer for casualties
 * @author Jorge Nunez L.
 */
class invoiceTransformer extends Transformer {
  static fetch({ folio, pdf, xml, serie, uuid, date, add_date: addDate,
    complement_invoice: complementInvoice
  }) {
    return {
      folio,
      pdf,
      xml,
      serie,
      complementInvoice: complementInvoice.length ? complementInvoice : null,
      uuid,
      date: moment(date).format('DD/MM/YYYY'),
      addDate: moment(addDate).format('DD/MM/YYYY')
    }
  }
}
export default invoiceTransformer
