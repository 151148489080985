/**
 * @vuepress
 * ---
 * title: reportChannelTransfomer
 */

import Transformer from '../transformer'

/**
 * @class reportChannelTransfomer
 * Info report channel
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 */
class reportChannelTransfomer extends Transformer {
  static fetch(report) {
    return {
      'slug': report.slug,
      'name': report.name
    }
  }
}
export default reportChannelTransfomer
