const BACKEND_ERROR_MSG = 'Error en servidor.<br>Contacte un administrador.'
const NO_RESPONSE_MSG = 'El servidor no envió respuesta.'

let makeErrorMessage

const ignoredKeyNames = ['message', 'detail', 'Message']

const SEPARATOR = '<br>'

makeErrorMessage = ({ data: errorData, config }, opts = {}) => {
  const optExcludedKeys = opts.ignoredKeyNames || []
  const keysTranslation = opts.keysTranslation || {}
  const separator = opts.separator || SEPARATOR

  let str = ''

  if (!errorData) return str

  if (typeof errorData === 'string') return errorData

  for (const key in errorData) {
    if (!errorData.hasOwnProperty(key)) continue

    const fieldData = errorData[key]

    if (!isNaN(key) || (
      ignoredKeyNames.indexOf(key) < 0 && optExcludedKeys.indexOf(key) < 0)) {
      let keyName = keysTranslation[key] || key
      str += `${separator}${keyName}: `
    }

    if (Array.isArray(fieldData)) {
      str += separator
      str += fieldData.map(s => JSON.stringify(s)).join(separator)
    } else if (typeof fieldData === 'object') {
      str += separator
      str += makeErrorMessage(fieldData)
    } else {
      const fData = `${fieldData}`.replace('\n', separator)
      str += `${separator}${fData}`
    }
  }

  str += `<br>${config.method && config.method.toUpperCase()}: ${config.url}`
  return str
}

let handleError
handleError = (error, opts = {}) => {
  const response = error.response

  if (!response) {
    return NO_RESPONSE_MSG
  }

  if (response.status >= 500) {
    if (process.env.NODE_ENV === 'development') {
      const { data: errorMessage } = response
      console.error(`%cSERVER ERROR DESCRIPTION %c ${errorMessage.substring(0, 600)}`,
        'font-size: 18px',
        'font-size: 10px; text-overflow: ellipsis;white-space: nowrap; overflow: hidden;',
        { errorMessage })
      return `${BACKEND_ERROR_MSG} ${opts.separator || SEPARATOR} ${errorMessage}`
    } else {
      return `${BACKEND_ERROR_MSG} <br>${response.config.method.toUpperCase()}: ${response.config.url}`
      // return BACKEND_ERROR_MSG
    }
  }

  return makeErrorMessage(response, opts)
}

export default handleError
