
import { fetchAccount, fetchUser, fetchPassword } from './fetch'
import updateAccount from './updateAccount'

export default {
  fetch: fetchAccount,
  fetchAccount,
  fetchUser,
  fetchPassword,
  updateAccount
}
