
class Storage {
  static setItem (...args) {
    return localStorage.setItem(...args)
  }

  static getItem (...args) {
    return localStorage.getItem(...args)
  }

  static removeItem (...args) {
    return localStorage.removeItem(...args)
  }
}

export default {
  install (Vue) {
    Vue.$storage = Storage
    Object.defineProperty(Vue.prototype, '$storage', {
      get () {
        return Storage
      }
    })
  }
}
