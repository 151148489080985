import Vue from 'vue'
import RegisterSinisterJobType from '@/transformers/sinister/jobType'

/**
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 *
 * Fetch job types list
 * @return {Object} job type list info
 */

export default () =>
  Vue.$http.get('sinister/job_type/')
    .then(response => {
      return RegisterSinisterJobType.fetchCollection(response.data).sort((a, b) =>
        a.name > b.name ? a.name === b.name ? 0 : 1 : -1
      )
    })
