<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="primary"
        icon
        target="_blank"
        :href="href"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span> {{ tooltip }} </span>
  </v-tooltip>
</template>

<script>
export default {

  name: 'AppAPI',

  props: {
    icon: {
      default: 'mdi-plus',
      type: String
    },
    href: {
      default: '#',
      type: String
    },
    tooltip: {
      type: String
    }
  },

  data: () => ({})
}
</script>

<style lang="css" scoped>
</style>
