/**
 * @vuepress
 * ---
 * title: Account
 * ---
 */

import Transformer from '@/transformers/transformer'
import assetURL from '@/utils/assetURL'
import GroupTransformer from './group'
import { reducePermissions } from './permissions'
import onlyProvider from '@/transformers/providers/onlyProvider'

/**
 * @class AccountTransformer
 * Account Info transformer
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 */
class AccountTransformer extends Transformer {
  /**
   * Transform fetched accont info
   *
   * @param account The fetched account
   *
   * @returns {Object} The transformed account
   */
  static fetch(account) {
    const groupsData = GroupTransformer.fetchCollection(account.groups)
    const groups = groupsData.map(g => {
      const { permissions, ...data } = g
      return data
    })

    const permissions = reducePermissions(groupsData.map(g => g.permissions))

    return {
      // Profile information
      profileId: account.id,
      emailAlternative: account.email,
      phone: account.phone,
      cellphone: account.cellphone,

      userId: account.id,
      lastLogin: account.last_login, // Last_Login
      username: account.username,
      treatment: account.treatment,
      fullName: `${account.first_name} ${account.last_name} ${account.mothers_last_name}`,
      firstName: account.first_name,
      lastName: account.last_name,
      secondLastName: account.mothers_last_name,
      email: account.email,
      isActive: account.is_active, // Is active or no
      dateJoined: account.date_joined,
      userPermissions: account.user_permissions,
      auxavatar: assetURL(account.photo),
      client: account.customer,
      // Groups and permissions
      groups,
      permissions,
      avatar: assetURL(account.photo),
      provider: !!account.provider ? onlyProvider.fetch(account.provider) : account.provider
    }
  }

  /**
   * Transform account info to be sent.
   *
   * @param account The account to be sent.
   *
   * @returns {Object} The transformed account
   */
  static send(account) {
    return {
      id: account.userId,
      user_name: account.username,
      treatment: account.treatment,
      first_name: account.firstName,
      last_name: account.lastName,
      mothers_last_name: account.secondLastName,
      email: account.email,
      avatar: account.avatar
      // Write transformers
    }
  }

  /**
   * Update user data
   * @param account The account to be updated.
   * @returns {Object} The transformed account
   */
  static update(account) {
    const { avatar: photo } = account

    return {
      first_name: account.firstName,
      email: account.email,
      last_name: account.lastName,
      mothers_last_name: account.secondLastName,
      treatment: account.treatment,
      phone: account.phone,
      cellphone: account.cellphone,
      photo: (account.avatar !== undefined && (typeof photo !== 'string' || photo.startsWith('data:image')))
        ? photo
        : undefined
    }
  }
}

export default AccountTransformer
