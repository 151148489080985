import Vue from 'vue'
import casualtyTransformer from '@/transformers/sinister/affected'

/**
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 *
 * @param casualty casualty's info to be sent
 *
 * Creates a new casualty
 * @return {Object} casualty info
 */

export default (slug, casualty) =>
  Vue.$http.post(`sinister/${slug}/affected/`, casualtyTransformer.send(casualty))
    .then(res => res.data)
