/**
 * @vuepress
 * ---
 * title: Router configuration
 * headline: Routes setup and settings
 * ---
 */

import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/pages/Login.vue'

import Layout from '@/layout/TheLayout.vue'

import errorsRouter from './modules/errors'

import routerPermission from './permission'
import routerPageTitle from './pageTitle'
import routerRoutes from './routes'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/dashboard',
        alias: '/',
        component: () => import('@/pages/Dashboard'),
        name: 'Dashboard',
        meta: {
          title: 'Dashboard',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true,
          showInSidebar: false
        }
        // :TODO: Implement group based redirection
        // redirect: '/siniestros/listado',
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/theme',
        component: () => import('@/components/Theme.vue'),
        name: 'Theme',
        meta: { title: 'route.theme', icon: 'mdi-palette' }
      }
    ]
  }
]

export const asyncRoutes = [
  /** When your routing table is too long, you can split it into small modules */
  errorsRouter,
  { path: '*', redirect: '/error/404', hidden: true }
]

/**
 *
 * App Router
 */
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    ...constantRoutes,
    ...routerRoutes
  ]
})

routerPermission(router)
routerPageTitle(router)

export default router
