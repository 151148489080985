
import { makeErrorMessage } from '@/utils'

const errorToString = (error, opts = {}) => {
  try {
    return makeErrorMessage(error, opts)
  } catch (err) {
    console.error('ERROR managing error', err)
    return error.toString()
  }
}

function HandledError(wrapped) {
  this.wrapped = wrapped
}

function wrap(attr) {
  Object.defineProperty(HandledError.prototype, attr, {
    get: function() {
      return this.wrapped[attr]
    }
  })
}

HandledError.prototype = Object.create(Error.prototype)
HandledError.prototype.constructor = HandledError

wrap('name')
wrap('message')
wrap('stack')
wrap('fileName')
wrap('lineNumber')
wrap('columnNumber')

HandledError.prototype.toString = function() {
  return this.wrapped.toString()
}

HandledError.prototype.htmlString = function() {
  return errorToString(this.wrapped)
}

HandledError.prototype.prettyString = function() {
  return errorToString(this.wrapped, { separator: '\n' })
}

export default HandledError
