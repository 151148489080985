
import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'QuotationPages',
    component: Layout,
    meta: {
      excludeGroup: EXTRANET_GROUPS,
      ifCan: {
        view: 'Quotation/quotation'
      }
    },
    children: [
      {
        path: '/siniestros/:sinisterSlug/cotizacion',
        name: 'AffectedQuotation',
        component: () => import('@/pages/Sinister/Quotation/'),
        props: true,
        meta: {
          title: 'Cotización'
        }
      },
      {
        path: '/siniestros/:sinisterSlug/cotizacion/:casualtyId/detalle',
        name: 'viewQuotation',
        component: () => import('@/pages/Sinister/QuotationDetail/index'),
        props: true,
        meta: {
          title: 'Detalle de cotización'
        }
      },
      {
        path: '/siniestro/:sinisterSlug/afectado/:affectedId/pactar-monto',
        name: 'providerAssignation',
        component: () => import('@/pages/Sinister/Provider/index'),
        props: true,
        meta: {
          title: 'Proveedores asignados'
        }
      },
      {
        path: '/siniestro/:sinisterSlug/cotizacion/:casualtyId/print',
        name: 'printQuotation',
        component: () => import('@/pages/Sinister/QuotationPrint/index'),
        props: true,
        meta: {
          title: 'Versión de impresión'
        }
      },
    ]
  }
]
