/**
 * @vuepress
 * ---
 * title: affected provider transformer
 */

import Transformer from '../transformer'

const userFullName = ({ name, last_name: lastName, mothers_last_name: secondLastName }) =>
  `${name || ''} ${lastName || ''} ${secondLastName || ''}`

class providerTransformer extends Transformer {
  /**
   * @author José Luis Ruiz
   */

  static fetch(provider) {
    return {
      'id': provider.id,
      'fullName': provider.name ? userFullName(provider) : null,
      'name': provider.name,
      'lastName': provider.last_name,
      'secondLastName': provider.mothers_last_name,
      'commercialName': provider.commercial_name,
      'phone': provider.provider_address[0] ? provider.provider_address[0].main_phone_number : '',
      'contactName': provider.provider_address[0] ? provider.provider_address[0].contact_name : '',
      'curp': provider.curp,
      'doesBill': provider.does_bill,
      'mainFamily': provider.main_family,
      'providerType': provider.provider_type,
      'rfc': provider.rfc,
      'secondaryFamily': provider.secondary_family,
      'slug': provider.slug
    }
  }
  static send(provider) {
    return {
      'commercial_name': provider.commercialName,
      'does_bill': provider.doesBill,
      'rfc': provider.rfc,
      'curp': provider.curp,
      'contact_name': provider.contactName,
      'main_phone_number': provider.phone,
      'name': provider.name,
      'last_name': provider.lastName,
      'mothers_last_name': provider.secondLastName,
      'main_family': provider.mainFamily,
      'secondary_family': provider.secondaryFamily
    }
  }
}
export default providerTransformer
