
import money from './money'
import moneyLong from './moneyLong'
import percentage from './percentage'
import booleanToState from './booleanToState'
import fromNowDate from './fromNowDate'
import shortDate from './shortDate'
import shortShortDate from './shortShortDate'
import compareDates from './compareDates'
import commonIcons from './commonIcons'
import longDate from './longDate'

export {
  money,
  fromNowDate
}

export default {
  install(Vue) {
    Vue.filter('money', money)
    Vue.filter('moneyLong', moneyLong)
    Vue.filter('percentage', percentage)
    Vue.filter('booleanToState', booleanToState)
    Vue.filter('fromNowDate', fromNowDate)
    Vue.filter('shortDate', shortDate)
    Vue.filter('shortShortDate', shortShortDate)
    Vue.filter('icon', commonIcons)
    Vue.filter('dateDiff', compareDates)
    Vue.filter('longDate', longDate)
  }
}
