
const fileExtensionMap = {
  jpg: 'mdi-image',
  xml: 'mdi-xml',
  png: 'mdi-image',
  pdf: 'mdi-file-pdf',
  doc: 'mdi-file-word-box',
  docx: 'mdi-file-word-box',
  xls: 'mdi-microsoft-excel',
  xlsx: 'mdi-microsoft-excel'
}

export default (link) => {
  const ext = (link || '').split('.').pop().toLowerCase()
  return fileExtensionMap[ext] || 'mdi-file'
}
