/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import {
  STORE,
  DELETE,
  CREATE,
  UPDATE
} from './mutation-types'

const logError = (type) => {
  console.error('Trying to access unexistant catalog in store', type)
}

export default {
  [STORE] (state, { prefix, data, catalog }) {
    const dict = prefix ? state[prefix] : state
    if (!dict) {
      console.error('Cant store data', { prefix, data, catalog })
      return
    }
    // console.log('', { data, prefix, catalog, dict })
    dict[catalog] = data
  },
  [DELETE] (state, { type, data }) {
    let catList = state[type]

    if (!catList) {
      logError(type)
      return
    }

    let index = catList.indexOf(data)
    catList.splice(index, 1)
  },
  [UPDATE] (state, { type, data, old_data: oldData }) {
    let catList = state[type]
    if (!catList) {
      logError(type)
      return
    }

    let index = catList.indexOf(oldData)

    if (index !== -1) {
      catList.splice(index, 1, data)
    } else {
      console.error('Could not update catalog vuex store', data, oldData)
    }
  },
  [CREATE] (state, { type, data }) {
    let catList = state[type]

    if (!catList) {
      logError(type)
      return
    }

    catList.push(data)
    console.log('INSERTED', data, catList.length)
  }
}
