
import store from '@/store'

const routerSetup = (router) => {
  router.beforeEach((to, from, next) => {
    if (!to.meta) {
      return next()
    }
    const { ifCan, ifInGroup, excludeGroup } = to.meta

    if (!ifCan && !ifInGroup && !excludeGroup) {
      return next()
    }

    const entries = Object.entries(ifCan || {})

    for (const [permission, entry] of entries) {
      let appModels = entry
      if (!Array.isArray(appModels)) {
        appModels = [appModels]
      }

      appModels = appModels.map(s => s.split('/'))

      for (const [app, model] of appModels) {
        if (!store.getters['account/userCan'](permission, app, model)) {
          console.error('user does not have permission', { permission, app, model })
          next({
            name: 'Page403'
          })
        }
      }
    }

    if (ifInGroup) {
      const groups = Array.isArray(ifInGroup) ? ifInGroup : [ifInGroup]
      const belongsToAGroup = groups.some(group =>
        store.getters['account/userInGroup'](group))
      if (!belongsToAGroup) {
        console.warn('user not belongs to any of the groups', groups)
        return next({
          name: 'Page403'
        })
      }
    }
    if (excludeGroup) {
      const groups = Array.isArray(excludeGroup) ? excludeGroup : [excludeGroup]
      const belongsToAGroup = groups.some(group =>
        store.getters['account/userInGroup'](group))
      if (belongsToAGroup) {
        console.warn('this group is forbidden to access', groups)
        return next({
          name: 'Page403'
        })
      }
    }

    return next()
  })
}

export default routerSetup
