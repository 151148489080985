/**
 * @vuepress
 * ---
 * title: Additional Information GNP
 * ---
 */

import Transformer from '../transformer'
class AdditionalInformation extends Transformer {
  static fetch({ origin = '', description = '', criteria = '', observations = '', id }) {
    return {
      id,
      origin,
      description,
      criteria,
      observations
    }
  }

  static send({ budget, origin = '', description = '', criteria = '', observations = '' }) {
    let payload = {
      budget,
      origin,
      description,
      criteria,
      observations
    }
    return payload
  }
}
export default AdditionalInformation
