import store from '@/store'
import notificationTransformer from '../transformers/'
import Vue from 'vue'

const logAlert = (a) => { console.log(a) }

const connectSocket = (onMessageCallback) => {
  const token = store.state.auth.accessToken
  const protocolPrefix = Vue.config.debug ? 'ws' : 'ws'
  const url = process.env.NOTIFICATIONS_SOCKET || 'localhost:8007'
  const address = Vue.config.debug ? url  : location.host

  if (!Vue.notificationSocket) {
    const socketURI = `${protocolPrefix}://${address}/ws/notifications/?token=${token}`
    // console.log('CONNECTING TO SOCKET', { socketURI })
    const notificationSocket = new WebSocket(
      socketURI
    )
    const newAlert = onMessageCallback ? onMessageCallback : logAlert
    notificationSocket.onmessage = function (e) {
      console.log('RECEIVED WS MESSAGE', { e })
      const data = JSON.parse(e.data)
      if (data.data) {
        newAlert(notificationTransformer.fetch(data))
      } else {
        newAlert('NO_DATAA')
      }
    }

    notificationSocket.onclose = function (e) {
      console.error('Chat socket closed unexpectedly')
      Vue.notificationSocket = null
    }

    Vue.notificationSocket = notificationSocket
    return Promise.resolve(notificationSocket)
  }
  return Promise.resolve(null)
}

export default connectSocket
