import Transformer from '../transformer'
import suppliesTransformer from './supply'

class ConceptTransformer extends Transformer {
  static fetch({ position, budget, code, name, unit_price_MXN: unitPriceMXN, quantity,
    apply_taxes: applyTaxes, supplies, taxes, id, concept, unit_measurement: unitMeasurement,
    assignation, quotation_concept: quotationConcept, category }) {
    const suppliesTrans = suppliesTransformer.fetchCollection(supplies).map(s => {
      return {
        ...s,
        concept: id
      }
    })
    const suppliesTotal = suppliesTrans.reduce((acc, a) => acc + +a.quantity * +a.zonePrice, 0)
    const priceTrans = +unitPriceMXN !== 0 ? +unitPriceMXN : suppliesTotal
    return {
      id: concept,
      concept: id,
      quotationConcept,
      budget,
      name: name || null,
      code,
      position,
      price: priceTrans,
      subtotal: priceTrans,
      quantity: +quantity,
      applyTaxes,
      taxes,
      total: +taxes + +priceTrans * quantity,
      unitMeasurement,
      unitMeasurementName: unitMeasurement.name,
      unitMeasurementAbbr: unitMeasurement.abbr,
      supplies: suppliesTrans,
      provider: assignation ? typeof assignation === 'object' ? assignation.provider_number : assignation : null,
      category: category || null
    }
  }

  static send({ concept, slug, name, price = 0, quantity = 0, supplies, position, unitMeasurement, category }) {
    // const totalSupplies = supplies.reduce((a, b) => a + b.zonePrice * b.quantity, 0)
    const supplyHasChanges = supplies.some((s) => s.QSupplyModified)
    return {
      concept: concept || slug,
      name,
      unit_measurement: (unitMeasurement && unitMeasurement.id) || unitMeasurement,
      position,
      price: +price.toFixed(2),
      quantity: +quantity,
      // update_supplies: +totalSupplies === +price ? suppliesTransformer.sendCollection(supplies) : []
      update_supplies: supplyHasChanges ? suppliesTransformer.sendCollection(supplies) : [],
      category: category || null
    }
  }
}

export default ConceptTransformer
