
import macro from './macro'

/**
 * Fetches configured preloaded data.
 * @return {Promise} A Promise to fetch all data.
 */
const prefetchService = () => {
  return Promise.all([
    ...macro()
  ].map(p => p.catch(err => console.error('Error fetching', err))))
}

export default prefetchService
