/**
 * @vuepress
 * ---
 * title: asignation transformer
 */

import Transformer from '../transformer'
function checkProviderNumber(resource) {
  if (!resource.provider_number) {
    return resource.id
  } else {
    return resource.provider_number
  }
}
function checkName(resource) {
  let name = ''
  if (resource.name) {
    name = resource.name
    if (resource.last_name) {
      name = `${name} ${resource.last_name}`
    }
    if (resource.second_last_name) {
      name = `${name} ${resource.last_name} ${resource.second_last_name}`
    }
  } else if (resource.rfc) {
    name = resource.rfc
  } else if (resource.phone) {
    name = resource.phone
  }
  return name
}

class assignationTransformer extends Transformer {
  static fetch(provider) {
    return {
      id: provider.id,
      providerNumber: checkProviderNumber(provider.resource),
      commercialName: provider.resource.commercial_name,
      name: checkName(provider.resource),
      resource: provider.resource,
      kind: provider.kind,
      assignationDate: provider.assignation_date,
      chageDate: provider.chage_date,
      comments: provider.comments
    }
  }
}
export default assignationTransformer
