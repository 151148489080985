
import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'Division',
    component: Layout,
    children: [
      {
        path: '/divisiones',
        name: 'Division list',
        props: true,
        component: () => import('@/pages/catalogs/divisions/index.vue'),
        meta: {
          ifCan: {
            view: 'divisions/divisions'
          },
          showInSidebar: true,
          icon: 'mdi-account-group',
          title: 'Divisiones',
          excludeGroup: EXTRANET_GROUPS
        }
      }
    ]
  }
]
