<template>
  <p>
    <template v-for="(tag, i) in value">
      <span
        :key="i"
        class="font-weight-medium indigo--text"
        v-if="tag.includes('[[') && tag.includes(']]')"
      >
        {{ getUser(tag) }}</span
      >
      <span
        :key="i"
        class="font-weight-regular"
        v-else
        v-html="htmlComment(tag)"
      ></span>
    </template>
  </p>
</template>

<script>

export default {
  name: 'AppNotification',
  props: {
    value: {
      type: Array
    }
  },
  data: () => ({
  }),

  methods: {
    getUser(tag) {
      const user = tag.split(/\[\[(.*?)\]\]/g)[1]
      return user
      // const found = this.fetchedUsers.find(u => u.username === user)
      // console.log(found)
      // return found ? found.fullName : ''
    },
    htmlComment(tag) {
      return tag.replace("\n", "<br/>")
    }
  }
}
</script>
