<template>
  <v-content color="blue">
    <transition
      name="fade-transform"
      mode="out-in">
      <router-view /> <!-- Main Dashboard content-->
    </transition>
    <slot> </slot>
  </v-content>
</template>

<script>
export default {
  name: 'TheLayoutContent',
  data: () => ({})
}
</script>
