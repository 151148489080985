import Vue from 'vue'
import sinisterTransformer from '@/transformers/sinister/sinister'

/**
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 *
 * @param slug sinister's slug
 *
 * Fetched sinister for edition. This endpoint applies a filter to validate if this sinister belongs to CoDi's division
 * @return {Object} report modes list info
 */

export default (slug = '') =>
  Vue.$http.get(`sinister/detail/${slug}/`)
    .then(response => slug ? sinisterTransformer.fetch(response.data) : sinisterTransformer.fetchCollection(response.data))
