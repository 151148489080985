/**
 * @vuepress
 * ---
 * title: Family assignation
 */

import Transformer from '../transformer'

class assignTransformer extends Transformer {
  /**
   * Family assignation. Used in Affected transformer
   * @author José Luis R.
   */

  static fetch(f) {
    const getProviderInfo = (assign) => {
      const a = assign[0]
      if (a) {
        return {
          comments: a.comments,
          providerName: a.provider.name,
          commercialName: a.provider.commercial_name,
          providerNumber: a.provider.provider_number,
          rfc: a.provider.rfc,
          curp: a.provider.curp,
          providerId: a.provider.id,
          providerSlug: a.provider.slug,
          id: a.id
        }
      }
      return {}
    }
    return {
      ...getProviderInfo(f.assignation),
      mainFamilyName: f.services_family.name,
      mainFamily: f.services_family.codename,
      familySlug: f.services_family.slug,
      familyId: f.services_family.id
    }
  }
}
export default assignTransformer
