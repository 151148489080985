
import Vue from 'vue'
import store from '@/store'

/**
 * Obtain API token
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 * @param  {string} username The username
 * @param  {string} password The user password
 * @return {Promise} An http request to obtain user token
 */
// Example data json post from login.vue
const login = ({ username, password }) => {
  return Vue.$http.post('/auth/obtain_token/', { username, password })
    .then(response => {
      const token = response.data.token
      store.dispatch('auth/login', token)
      return token
    })
}

export default login
