<template>
  <v-app>
    <router-view/> <!-- differents views-->
  </v-app>
</template>

<script>

import prefetchService from '@/services/prefetch'
import { mapState } from 'vuex'

/**
 * Kraken entry component
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 */
export default {
  name: 'App',

  computed: {
    ...mapState({
      authenticated: ({ auth: { authenticated } }) => authenticated
    })
  },

  mounted () {
    if (this.$store.state.auth.authenticated) {
      prefetchService()
    }
  },

  watch: {
    authenticated(value) {
      if (value) {
        prefetchService()
      }
    }
  }
}

</script>
